import axios from "axios";

const searchUser = async (token, roomNumber) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/search_user`,
      { roomNumber },
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const usersService = { searchUser };

export default usersService;
