import { useTranslation } from "react-i18next";
import { ArrowDownSvg, CloseSvg } from "../icons";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

const TableSearchSelect = ({
  setFormData,
  formData,
  data,
  searchKey,
  selectedValue,
  translationKey,
  valueKey,
}) => {
  const { t } = useTranslation();

  const handleChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      [searchKey]: value[valueKey],
      page: 1,
    }));
  };

  const handleReset = () => {
    setFormData((prevData) => ({
      ...prevData,
      [searchKey]: "",
      page: 1,
    }));
  };

  return (
    <Listbox onChange={handleChange}>
      <ListboxButton className="text-xs flex items-center justify-between h-full cursor-pointer text-blue-4 font-medium">
        <div className="min-w-4 w-4">
          <ArrowDownSvg />
        </div>
      </ListboxButton>
      <ListboxOptions className="absolute z-20 h-max max-h-60 overflow-scroll top-full left-1/2 transform -translate-x-1/2 mt-2 w-max bg-white-1 border border-purple-2 rounded-md shadow-lg">
        {formData[searchKey] !== "" && (
          <div className="w-full flex justify-between items-center py-1 px-2 bg-purple-2/40">
            <span>{t(selectedValue)}</span>
            <button onClick={handleReset}>
              <CloseSvg />
            </button>
          </div>
        )}
        {data.map((item, index) => (
          <ListboxOption
            key={index}
            value={item}
            className="py-1 px-2 overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer transition-all duration-300 hover:bg-purple-2/40"
          >
            {t(item[translationKey])}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};

export default TableSearchSelect;
