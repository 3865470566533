import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchUser } from "../../features/groups/UsersSlice";
// import Checkbox from "./Checkbox";
// import InputSmall from "../common/InputSmall";
import MultiRoomNumbers from "./MultiRoomNumbers";

const OneUser = ({ formData, setFormData }) => {
  const [showRoomNumbers, setShowRoomNumbers] = useState(false);
  const { users, isLoading } = useSelector((state) => state.roomUsers);
  const debounceTimeout = useRef(null);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "userRoomNumbers" && value.length > 2) {
      setShowRoomNumbers(true);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        dispatch(searchUser(value));
      }, 500);
    } else {
      setShowRoomNumbers(false);
    }
  };

  const options = users?.map((user) => user.roomNumber) || [];

  return (
    <div className="w-full flex flex-col items-start gap-6">
      <MultiRoomNumbers
        options={options}
        showOptions={showRoomNumbers}
        isLoading={isLoading}
        name="userRoomNumbers"
        onChange={handleInputChange}
        setFormData={setFormData}
        formData={formData}
      />
    </div>
  );
};

export default OneUser;
