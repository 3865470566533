import { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Alert, Snackbar } from "@mui/material";
import ParcelsTable from "../table/groups/ParcelsTable";
import AddParcelForm from "../modals/groups/AddParcelForm";
import Modal from "../common/Modal";
import UploadParcelPictures from "../modals/groups/UploadParcelPictures";
import PicturesList from "../modals/groups/PicturesList";
import EditParcelForm from "../modals/groups/EditParcelForm";
import EditParcelPictures from "../modals/groups/EditParcelPictures";

const ParcelsLayout = ({
  currentPage,
  onNextPage,
  perPage,
  onPrevPage,
  handlePerPage,
  handlePageClick,
  showAddForm,
  showEditForm,
  addParcelFormRef,
  editParcelFormRef,
  formHeight,
  setShouldRefetch,
  handleShowEditForm,
  editParcelId,
  parcelsFormData,
  setParcelsFormData,
}) => {
  const [showUploadPicturesModal, setShowUploadPicturesModal] = useState(false);
  const [showEditPicturesModal, setShowEditPicturesModal] = useState(false);
  const [showPicturesListModal, setShowPicturesListModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [severity, setSeverity] = useState("");
  const [parcelId, setParcelId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [formData, setFormData] = useState({
    parcelGroupId: "",
    tds_code: "",
    roomNumber: "",
    parcelDetails: [
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      },
    ],
    warehouseComment: "",
    warehouseCategoryId: "",
    "file[]": [],
  });
  const [editFormData, setEditFormData] = useState({
    parcelId: "",
    tds_code: "",
    roomNumber: "",
    parcelDetails: [
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      },
    ],
    warehouseComment: "",
    warehouseCategoryId: "",
    "file[]": [],
  });

  const handleShowUploadPicturesModal = () => {
    setShowUploadPicturesModal(!showUploadPicturesModal);
  };

  const handleShowEditPicturesModal = () => {
    setShowEditPicturesModal(!showEditPicturesModal);
  };

  const handleShowPicturesList = (id) => {
    setShowPicturesListModal(true);
    setParcelId(id);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  return (
    <>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
      <AddParcelForm
        showAddForm={showAddForm}
        handleShowModal={handleShowUploadPicturesModal}
        addParcelFormRef={addParcelFormRef}
        formHeight={formHeight}
        formData={formData}
        setFormData={setFormData}
        setShouldRefetch={setShouldRefetch}
        setSeverity={setSeverity}
        setResponseMessage={setResponseMessage}
        setShowResponseMessage={setShowResponseMessage}
      />
      <EditParcelForm
        showEditForm={showEditForm}
        handleShowModal={handleShowEditPicturesModal}
        editParcelFormRef={editParcelFormRef}
        formHeight={formHeight}
        formData={editFormData}
        setFormData={setEditFormData}
        setShouldRefetch={setShouldRefetch}
        editParcelId={editParcelId}
      />
      <Modal
        showmodal={showEditPicturesModal}
        setShowModal={setShowEditPicturesModal}
      >
        <EditParcelPictures
          handleShowModal={handleShowEditPicturesModal}
          setFormData={setEditFormData}
          formData={editFormData}
        />
      </Modal>
      <Modal
        showmodal={showUploadPicturesModal}
        setShowModal={setShowUploadPicturesModal}
      >
        <UploadParcelPictures
          handleShowModal={handleShowUploadPicturesModal}
          setFormData={setFormData}
          formData={formData}
        />
      </Modal>

      <Modal
        showmodal={showPicturesListModal}
        setShowModal={setShowPicturesListModal}
      >
        <PicturesList
          parcelId={parcelId}
          handleShowModal={() => setShowPicturesListModal(false)}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      </Modal>
      {selectedImage && (
        <div className="fixed inset-0 z-50">
          <div
            className="w-full h-full bg-black/20"
            onClick={handleCloseImage}
          ></div>
          <div className="absolute max-w-6xxl w-full p-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
            <TransformWrapper disablePadding="true">
              <TransformComponent>
                <img className="w-full" src={selectedImage} alt="Selected" />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
      <ParcelsTable
        currentPage={currentPage}
        onNextPage={onNextPage}
        perPage={perPage}
        onPrevPage={onPrevPage}
        handlePerPage={handlePerPage}
        handlePageClick={handlePageClick}
        handleShowPicturesList={handleShowPicturesList}
        handleShowEditForm={handleShowEditForm}
        showEditForm={showEditForm}
        editParcelId={editParcelId}
        parcelsFormData={parcelsFormData}
        setParcelsFormData={setParcelsFormData}
      />
    </>
  );
};

export default ParcelsLayout;
