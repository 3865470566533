import React, { useState } from "react";

const TextArea = ({ font, showSignButton, setFormData }) => {
  const [isEmpty, setIsEmpty] = useState(true);

  const handleInput = (e) => {
    setIsEmpty(e.target.innerText.trim() === "");
    console.log(e.target);
  };

  return (
    <div className="!w-full flex flex-col items-start gap-4">
      <div
        className={`p-5 outline-none relative text-sm 2xl:text-base h-max w-full ${
          isEmpty
            ? "before:absolute before:content-['Start_writing_an_email'] before:text-gray-500 before:pointer-events-none before:top-5 before:left-5 text-sm 2xl:text-base"
            : ""
        }`}
        contentEditable
        suppressContentEditableWarning={true}
        style={{
          fontFamily: font,
        }}
        onInput={handleInput}
      ></div>
      {showSignButton && (
        <div className="px-5 flex justify-start items-center gap-2">
          <button className="p-2.5 bg-blue-2 rounded-xl text-xs text-white w-40">
            Sign
          </button>
          <button className="p-2.5 bg-blue-2/15 rounded-xl text-xs text-blue-2 w-40">
            dec
          </button>
        </div>
      )}
    </div>
  );
};

export default TextArea;
