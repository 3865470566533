import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIssuedParcels } from "../../features/dashboard/issuedParcels/IssuedParcelsSlice";
import { useTranslation } from "react-i18next";
import { ArrowRightSvg } from "../icons";
import { Link } from "react-router-dom";
import FilterByPeriods from "./components/FilterByPeriods";
import ProgressBar from "./components/ProgressBar";
import Spinner from "../common/Spinner";

const IssuedParcels = () => {
  const [formData, setFormData] = useState({
    reportType: "terminalRequests",
    groupPeriod: "",
    departmentId: null,
  });
  const { reportType, groupPeriod, departmentId } = formData;
  const { isLoading, terminalRequests } = useSelector(
    (state) => state.issuedParcels
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (reportType && groupPeriod) {
      dispatch(getIssuedParcels({ reportType, groupPeriod, departmentId }));
    }
  }, [reportType, groupPeriod, departmentId, dispatch]);

  const { requestsCount, withdrawParcelsCount, errorParcelsCount } =
    terminalRequests;

  return (
    <div className="relative min-h-80 flex flex-col justify-between items-end bg-white border border-black/5 p-4 rounded-xl">
      <div className="flex items-center justify-between w-full">
        <h2 className="text-base text-purple-5 font-medium">
          {t("text.issued_parcels")}
        </h2>
        <FilterByPeriods setFormData={setFormData} />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <ProgressBar
          requestsCount={requestsCount}
          withdrawParcelsCount={withdrawParcelsCount}
          errorParcelsCount={errorParcelsCount}
        />
      )}
      <div className="w-full flex justify-end mt-2">
        <Link
          to="/issuedParcelsDetails"
          className="flex items-center gap-2 mt-4 text-sm font-medium text-purple-1 hover:opacity-50 transition-opacity duration-300 whitespace-nowrap lg:text-xs xl:text-sm"
        >
          {t("text.view_details")}
          <ArrowRightSvg fill="#A183EA" />
        </Link>
      </div>
    </div>
  );
};

export default IssuedParcels;
