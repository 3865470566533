import { useSelector } from "react-redux";

const BarcodeModal = ({ setShowModal, barcodeImageValue }) => {
  const barcode = useSelector((state) => state.groups?.barcode);

  const handlePrint = (e) => {
    e.preventDefault();
    window.print();
  };

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-black bg-opacity-10 backdrop-blur-sm z-[9999999] flex justify-center items-center p-6">
      <div className="relative max-w-md w-full bg-white p-6 flex flex-col items-center justify-center rounded-lg">
        <h2 className="text-lg font-semibold text-blue-900 mb-2">Barcode</h2>
        <span className="text-sm text-gray-700">{barcodeImageValue}</span>
        <div className="bg-gray-100 p-4 rounded-md mt-4 w-full sm:w-auto">
          <img
            src={barcode.barcodeImage}
            alt="barcode"
            className="w-full object-contain"
          />
        </div>
        <div className="flex justify-center items-center gap-4 mt-7 no-print">
          <button
            className="text-blue-500 text-sm bg-gray-200 px-7 py-2 rounded-md hover:bg-gray-300 transition-colors"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
          <button
            className="text-white bg-blue-600 text-sm px-7 py-2 rounded-md hover:bg-blue-700 transition-colors"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default BarcodeModal;
