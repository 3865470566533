import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../../libs/AppContext";
import { updateGroup, getGroups } from "../../../features/groups/GroupsSlice";
import ButtonDefault from "../../buttons/ButtonDefault";
import Input from "../../common/Input";
import Select from "../../common/Select";
import ModalsHeadline from "../components/ModalsHeadline";

const EditGroup = ({
  closeModal,
  groupId,
  setShowEditGroup,
  setShowResponseMessage,
  setResponseMessage,
}) => {
  const [prevData, setPrevData] = useState({});
  const [formData, setFormData] = useState({
    parcelGroupId: "",
    barcode: "",
    warehouseCountryId: "",
    flightId: "",
    departmentId: "",
  });
  const [errors, setErrors] = useState({
    barcode: "",
    warehouseCountryId: "",
    departmentId: "",
  });
  const flights = useSelector((state) => state.groups?.flights);
  const departments = useSelector((state) => state.groups?.departments);
  const data = useSelector((state) => state.groups?.groups?.parcelGroups);
  const [flightsPrevValue, setFlightsPrevValue] = useState("");
  const [departmentPrevValue, setDepartmentPrevValue] = useState("");
  const dispatch = useDispatch();
  const resetSelectedOptionRef = useRef(null);
  const memoizedDepartments = useMemo(() => departments || [], [departments]);
  const memoizedFlights = useMemo(() => flights || [], [flights]);
  const departmentsOptions = Array.isArray(memoizedDepartments)
    ? memoizedDepartments.map((item) => item.address)
    : [];
  const flightsOptions = Array.isArray(memoizedFlights)
    ? memoizedFlights.map((item) => item.flightNumber)
    : [];
  const { parcelGroupId, barcode, warehouseCountryId, flightId, departmentId } =
    formData;
  const { groupsFormData } = useAppContext();

  useEffect(() => {
    const filteredGroup = data?.find(
      (group) => group.parcelGroupId === groupId
    );
    const getDepartmentName = memoizedDepartments?.find(
      (department) => department.id === filteredGroup?.departmentId
    );

    setFlightsPrevValue(filteredGroup?.flightNumber);
    setDepartmentPrevValue(getDepartmentName?.address);
    setPrevData(filteredGroup);
  }, [data, groupId, memoizedDepartments]);

  useEffect(() => {
    setFormData({
      parcelGroupId: groupId,
      barcode: prevData?.barcode || "",
      flightId: prevData?.flightId || "",
      departmentId: prevData?.departmentId || "",
    });
  }, [groupId, prevData]);

  const updateDepartmentId = (departmentId) => {
    setFormData({
      ...formData,
      departmentId: departmentId,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      departmentId: "",
    }));
  };

  const updateFlightsId = (flightId) => {
    setFormData({
      ...formData,
      flightId: flightId,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();

    if (!barcode) {
      setErrors({
        barcode: barcode ? "" : "Barcode is required",
        // departmentId: departmentId ? "" : "Department name is required",
      });
      return;
    }

    const groupData = {
      parcelGroupId,
      barcode,
      warehouseCountryId,
      flightId,
      departmentId,
    };

    try {
      const response = await dispatch(updateGroup(groupData));
      if (response.payload.type === "success") {
        dispatch(getGroups(groupsFormData));
        setShowEditGroup(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        setFormData({
          barcode: "",
        });
        if (resetSelectedOptionRef.current) {
          resetSelectedOptionRef.current();
        }
      }
      if (response.error.message === "Rejected") {
        setShowEditGroup(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          barcode: error.response.data.barcode || "",
          departmentId: error.response.data.barcode || "",
        });
      }
    }
  };

  return (
    <form
      className="flex flex-col justify-between rounded-lg h-full"
      onSubmit={handleEditGroup}
    >
      <div>
        <ModalsHeadline text="Update parcel group" onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text="List No"
              name="barcode"
              value={formData.barcode}
              onchange={handleInputChange}
              error={errors.barcode}
              errormark={errors.barcode ? "error-border" : ""}
            />
            {errors.barcode && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.barcode}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text="Flight No"
              options={flightsOptions}
              value={formData.flightId}
              type="flights"
              flightsData={memoizedFlights}
              updateFlightsId={updateFlightsId}
              resetSelectedOption={resetSelectedOptionRef}
              error={errors.flightId}
              prevOption={flightsPrevValue}
              errormark={errors.flightId ? "error-border" : ""}
            />
            {errors.flightId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.flightId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text="Select branch"
              options={departmentsOptions}
              value={formData.departmentId}
              type="groupsDepartments"
              departmentsData={memoizedDepartments}
              updateDepartmentId={updateDepartmentId}
              resetSelectedOption={resetSelectedOptionRef}
              error={errors.departmentId}
              prevOption={departmentPrevValue}
              errormark={errors.departmentId ? "error-border" : ""}
            />
            {errors.departmentId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.departmentId}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">Update parcel group</ButtonDefault>
      </div>
    </form>
  );
};

export default EditGroup;
