import { useSelector } from "react-redux";
import { useAppContext } from "../../../libs/AppContext";
import { useTranslation } from "react-i18next";
import {
  AddFileSvg,
  AttachSvg,
  DeleteSvg,
  EditSvg,
  UserSvg2,
} from "../../icons";
import Checkbox from "../../common/Checkbox";
import CustomDrop from "../../common/CustomDrop";
import Spinner from "../../common/Spinner";
import Tooltip from "@mui/material/Tooltip";
import CheckAll from "../../common/CheckAll";

const ClientsTable = ({
  handleShowFiles,
  handleShowClientInfo,
  handleShowEditClient,
  handleDeleteConfirmation,
  data,
}) => {
  const { isPermission } = useAppContext();
  const { isLoading } = useSelector((state) => state.clients);
  const { t } = useTranslation();

  // Define the columns and their corresponding keys
  const columns = [
    { header: t("text.inn"), key: "inn" },
    { header: t("text.user"), key: "fullName" },
    { header: t("text.email"), key: "email" },
    { header: t("text.country_and_city"), key: "countryAndCity" },
    { header: t("text.files"), key: "files" },
    { header: t("text.mobile"), key: "phone" },
  ];

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20">
          <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center">
                    <CheckAll />
                  </div>
                </th>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className="max-w-5 text-left border-b font-medium"
                  >
                    <div className="p-4 w-full truncate border-r border-black/5">
                      {column.header}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 border-black/5 border-b"
                >
                  <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                    <Checkbox />
                  </td>
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className="max-w-5 text-xs text-blue-4 font-medium"
                    >
                      <div className="py-2 px-3 w-full truncate border-r border-black/5">
                        {column.key === "files" ? (
                          <div className="flex justify-between items-center bg-white-1 rounded">
                            <div className="flex items-center gap-2">
                              <AttachSvg /> {row?.attachments?.length} files
                            </div>
                            <button
                              onClick={() => handleShowFiles(row.id)}
                              className="bg-blue-200 rounded transition-colors hover:bg-blue-300"
                            >
                              <AddFileSvg />
                            </button>
                          </div>
                        ) : column.key === "countryAndCity" ? (
                          <>
                            {row.country} - {row.cityName}
                          </>
                        ) : (
                          row[column.key]
                        )}
                      </div>
                    </td>
                  ))}
                  <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                    <div className="flex gap-2">
                      <Tooltip title={t("text.details")}>
                        <div onClick={() => handleShowClientInfo(row.id)}>
                          <UserSvg2 />
                        </div>
                      </Tooltip>
                      <CustomDrop>
                        {isPermission.clientsDelete?.action ===
                          "clientsDelete" && (
                          <div onClick={() => handleDeleteConfirmation(row.id)}>
                            <DeleteSvg />
                            {t("text.delete")}
                          </div>
                        )}
                        {isPermission.clientsEdit?.action === "clientsEdit" && (
                          <div onClick={() => handleShowEditClient(row.id)}>
                            <EditSvg />
                            {t("text.edit")}
                          </div>
                        )}
                      </CustomDrop>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ClientsTable;
