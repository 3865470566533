import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerScore } from "../../features/dashboard/customerScore/CustomerScoreSlice";
import { ArrowRightSvg } from "../icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FilterByPeriods from "./components/FilterByPeriods";
import likeImage from "../../assets/like.svg";
import dislikeImage from "../../assets/dislike.svg";
import passiveImage from "../../assets/passive.svg";
import Spinner from "../common/Spinner";

const SatisfactionScore = () => {
  const [formData, setFormData] = useState({
    reportType: "terminalRates",
    groupPeriod: "",
  });
  const { reportType, groupPeriod } = formData;
  const { isLoading, score } = useSelector((state) => state.score);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (reportType && groupPeriod) {
      dispatch(getCustomerScore({ reportType, groupPeriod }));
    }
  }, [reportType, groupPeriod, dispatch]);

  const { good, bad, passive } = score;

  const totalCount = good + bad + passive;

  const goodPercentage = (good / totalCount) * 100;
  const badPercentage = (bad / totalCount) * 100;
  const passivePercentage = (passive / totalCount) * 100;

  return (
    <div className="relative max-w-full w-full bg-white border border-black/5 p-4 rounded-2xl shadow-xl flex flex-col lg:min-h-xxl lg:max-w-52 xl:max-w-72 2xl:max-w-96">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-base text-purple-5 font-medium">
          {t("text.customer_satisfaction_score")}
        </h2>
        <FilterByPeriods setFormData={setFormData} />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col items-end w-full h-full justify-between">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-start gap-2 py-3 border-b border-purple-2/20 text-green-1">
              <div className="w-20 h-20 md:max-w-32 md:w-full md:max-h-32 md:h-full">
                <img
                  src={likeImage}
                  alt="like"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex flex-col items-start max-w-24">
                <div className="text-2xl font-medium leading-9">
                  {`${goodPercentage ? goodPercentage.toFixed(2) : 0}%`}
                </div>
                <span className="text-base leading-6 font-medium">
                  {t("text.promoters")}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-start gap-2 py-3 border-b border-purple-2/20 text-purple-1">
              <div className="w-20 h-20 md:max-w-32 md:w-full md:max-h-32 md:h-full">
                <img
                  src={passiveImage}
                  alt="passive"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex flex-col items-start max-w-24">
                <div className="text-2xl font-medium leading-9">
                  {`${passivePercentage ? passivePercentage.toFixed(2) : 0}%`}
                </div>
                <span className="text-base leading-6 font-medium">
                  {t("text.passives")}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-start gap-2 py-3 text-pink-1">
              <div className="w-20 h-20 md:max-w-32 md:w-full md:max-h-32 md:h-full">
                <img
                  src={dislikeImage}
                  alt="dislike"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex flex-col items-start max-w-24">
                <div className="text-2xl font-medium leading-9">
                  {`${badPercentage ? badPercentage.toFixed(2) : 0}%`}
                </div>
                <span className="text-base leading-6 font-medium">
                  {t("text.detractors")}
                </span>
              </div>
            </div>
          </div>
          <Link
            to="/satisfactionScoreDetails"
            className="flex items-center gap-2 mt-4 text-sm font-medium text-purple-1 hover:opacity-50 transition-opacity duration-300 whitespace-nowrap lg:text-xs xl:text-sm"
          >
            {t("text.view_details")}
            <ArrowRightSvg fill="#A183EA" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default SatisfactionScore;
