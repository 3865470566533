import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCategories,
  updateCategory,
} from "../../../features/categories/CategoriesSlice";
import Input from "../../common/Input";
import Select from "../../common/Select";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";

const EditCategory = ({
  setResponseMessage,
  setShowResponseMessage,
  handleShowEditCategory,
  categoryID,
  data,
  setShowEditCategory,
}) => {
  const [getBonusType, setGetBonusType] = useState("");
  const [prevValue, setPrevValue] = useState("");
  const [formData, setFormData] = useState({
    categoryId: categoryID,
    categoryName: "",
    bonusType: "",
    bonusValue: "",
  });
  const [errors, setErrors] = useState({
    categoryName: "",
    bonusType: "",
    bonusValue: "",
  });
  const options = ["fixed", "percentage"];
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { categoryId, categoryName, bonusType, bonusValue } = formData;

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      bonusType: getBonusType,
    }));
  }, [getBonusType]);

  useEffect(() => {
    const categoryData = data.find((category) => category.id === categoryID);

    if (categoryData) {
      setPrevValue(categoryData.bonusType);
      setFormData({
        categoryId: categoryID,
        categoryName: categoryData.name,
        bonusType: categoryData.bonusType,
        bonusValue: categoryData.bonusValue,
      });
    }
  }, [data, categoryID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdateCategory = async (e) => {
    e.preventDefault();

    if (!categoryName || bonusType === "" || !bonusValue) {
      setErrors({
        categoryName: categoryName ? "" : t("text.category_required"),
        bonusType: bonusType ? "" : t("text.bonus_type_required"),
        bonusValue: bonusValue ? "" : t("text.bonus_value_required"),
      });
      return;
    }

    const updatedData = {
      categoryId,
      categoryName,
      bonusType,
      bonusValue,
    };

    try {
      const response = await dispatch(updateCategory(updatedData));
      if (response.payload.type === "success") {
        dispatch(getCategories());
        setShowEditCategory(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      } else if (response.error.message === "Rejected") {
        setShowEditCategory(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {}
  };

  return (
    <form
      onSubmit={handleUpdateCategory}
      className="flex flex-col justify-between h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.edit_category")}
          onClick={handleShowEditCategory}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.name")}
              name="categoryName"
              value={formData.categoryName}
              onchange={handleInputChange}
              error={errors.categoryName}
              errormark={errors.categoryName ? "border-red-500" : ""}
            />
            {errors.categoryName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute bottom-[-7px] left-4">
                {errors.categoryName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text={t("text.bonusType")}
              options={options}
              value={formData.bonusType}
              type="bonus"
              setGetBonusType={setGetBonusType}
              prevOption={prevValue}
              error={errors.bonusType}
              errormark={errors.bonusType ? "border-red-500" : ""}
            />
            {errors.bonusType && (
              <p className="text-xs font-normal text-red-500 bg-white absolute bottom-[-7px] left-4">
                {errors.bonusType}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.bonusValue")}
              name="bonusValue"
              value={formData.bonusValue}
              onchange={handleInputChange}
              error={errors.bonusValue}
              errormark={errors.bonusValue ? "border-red-500" : ""}
            />
            {errors.bonusValue && (
              <p className="text-xs font-normal text-red-500 bg-white absolute bottom-[-7px] left-4">
                {errors.bonusValue}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="p-6">
        <ButtonDefault type="submit" className="mt-4">
          {t("text.update")}
        </ButtonDefault>
      </div>
    </form>
  );
};

export default EditCategory;
