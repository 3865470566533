import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getFileCategories,
  updateFileCategory,
} from "../../../features/filecategories/FileCategoriesSlice";
import Input from "../../common/Input";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";

const EditFileCategory = ({
  setShowEditFileCategories,
  setResponseMessage,
  setShowResponseMessage,
  fileId,
  handleShowEditFile,
}) => {
  const [prevData, setPrevData] = useState({});
  const [formData, setFormData] = useState({
    categoryId: "",
    categoryName: "",
  });
  const [errors, setErrors] = useState({
    categoryName: "",
  });
  const { fileCategories } = useSelector((state) => state.fileCategories);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { categoryId, categoryName } = formData;

  useEffect(() => {
    const filteredCategory = fileCategories?.find(
      (category) => category.id === fileId
    );

    setPrevData(filteredCategory);
  }, [fileCategories, fileId]);

  useEffect(() => {
    setFormData({
      categoryId: fileId,
      categoryName: prevData?.name || "",
    });
  }, [prevData, fileCategories, fileId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditFileCategory = async (e) => {
    e.preventDefault();

    if (!categoryName) {
      setErrors({
        categoryName: categoryName ? "" : `${t("text.file_category_required")}`,
      });
      return;
    }

    const updatedFileCategoryData = {
      categoryId,
      categoryName,
    };

    try {
      const response = await dispatch(
        updateFileCategory(updatedFileCategoryData)
      );
      if (response.payload.type === "success") {
        dispatch(getFileCategories());
        setShowEditFileCategories(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }

      if (response.error.message === "Rejected") {
        setShowEditFileCategories(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          categoryName: error.response.data.categoryName || "",
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleEditFileCategory}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.edit_file_category")}
          onClick={handleShowEditFile}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.name")}
              name="categoryName"
              value={formData.categoryName}
              onchange={handleInputChange}
              error={errors.categoryName}
              errormark={errors.categoryName ? "error-border" : ""}
            />
            {errors.categoryName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.categoryName}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.update")}</ButtonDefault>
      </div>
    </form>
  );
};

export default EditFileCategory;
