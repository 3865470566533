import { useEffect, useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useSelector } from "react-redux";
import { DashboardArrowDownSvg } from "../../icons";
import { useAppContext } from "../../../libs/AppContext";
import { useTranslation } from "react-i18next";

const FilterByPeriods = ({ setFormData }) => {
  const [period, setPeriod] = useState({});
  const periods = useSelector((state) => state.periods?.periods);
  const { setGlobalPeriod } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (periods && periods.length > 0) {
      setPeriod(periods[0]);
    }
  }, [periods]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      groupPeriod: period?.period,
    }));

    setGlobalPeriod(period?.period);
  }, [setFormData, period, setGlobalPeriod]);

  const handleChange = (selectedPeriod) => {
    setPeriod(selectedPeriod);

    setFormData((prevData) => ({
      ...prevData,
      groupPeriod: selectedPeriod?.period,
    }));
  };

  return (
    <div className="relative">
      <Listbox value={period} onChange={handleChange}>
        {({ open }) => (
          <>
            <ListboxButton className="flex items-center justify-center text-purple-5/70 text-xs font-medium cursor-pointer">
              {t(period?.periodDictionaryKey)}
              <DashboardArrowDownSvg />
            </ListboxButton>
            {open && (
              <ListboxOptions className="absolute w-44 right-0 bg-white-1 border border-purple-5/30 rounded-lg shadow-md overflow-hidden z-50">
                {periods?.map((item, index) => (
                  <ListboxOption
                    key={index}
                    value={item}
                    className="text-purple-5 text-xs font-medium py-1.5 px-2 transition-colors duration-300 border-b border-black/5 hover:bg-purple-2 hover:bg-opacity-20"
                  >
                    {t(item.periodDictionaryKey)}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

export default FilterByPeriods;
