import {
  CircularProgress,
  createTheme,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { DashboardUserSvg } from "../../icons";

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: "rgba(161, 131, 234, 1)",
          backgroundColor: "rgba(233, 236, 241, 1)",
          borderRadius: "50%",
        },
      },
    },
  },
});

const convertTimeToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

const ResponseTimeDetailsElement = ({
  detail,
  longestTime,
  type,
  onClick,
  isActive,
}) => {
  const avgTimeInSeconds = convertTimeToSeconds(detail.avgTime);
  const longestTimeInSeconds = convertTimeToSeconds(longestTime);
  const progressPercentage = (avgTimeInSeconds / longestTimeInSeconds) * 100;

  return (
    <div
      onClick={onClick}
      className={`bg-white-1 border border-black/5 rounded-xl p-3 flex items-center gap-5 cursor-pointer ${
        isActive ? "bg-purple-1/15" : ""
      }`}
    >
      <div className="">
        <ThemeProvider theme={theme}>
          <CircularProgress
            variant="determinate"
            value={progressPercentage}
            className="absolute"
            sx={{ height: 50, width: 50 }}
          />
          <div
            className={`bg-white h-10 w-10 rounded-full flex items-center justify-center z-10 ${
              isActive ? "bg-purple-1/15" : ""
            }`}
          >
            <div className="absolute bg-white-1 h-8 w-8 rounded-full flex items-center justify-center">
              <DashboardUserSvg />
            </div>
          </div>
        </ThemeProvider>
      </div>

      <div className="flex flex-col items-start gap-1">
        <Tooltip
          title={
            type === "deliveryTime" ? detail.changeUser : detail.dstChannel
          }
        >
          <div className="text-blue-5 text-sm font-medium truncate whitespace-nowrap overflow-hidden text-ellipsis max-w-[calc(100%-53px)]">
            {type === "deliveryTime" ? detail.changeUser : detail.dstChannel}
          </div>
        </Tooltip>
        <span className="text-black/40 text-xs font-medium">
          {detail.avgTime}
        </span>
      </div>
    </div>
  );
};

export default ResponseTimeDetailsElement;
