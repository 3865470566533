import { Link } from "react-router-dom";
import { BackSvg } from "../icons";

const TableHeadline = (props) => {
  const { text } = props;
  return (
    <div className="w-full p-2 border-b border-black/5 bg-gray-100 flex flex-col justify-between items-center gap-2 md:flex-row md:gap-4 md:p-4">
      <div className="flex items-center justify-start gap-3 w-full">
        <Link
          to="/MainPage"
          className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
        >
          <BackSvg />
        </Link>
        <div className="text-lg font-semibold text-gray-800 md:text-xl">
          {text}
        </div>
      </div>
    </div>
  );
};

export default TableHeadline;
