import { ReactBarcode as Barcode } from "react-jsbarcode";
import logo from "../../../assets/space-cargo-logo-2.svg";

const ParcelPrint = ({ printRef, data, formData }) => {
  const barcodeValue = formData?.tds_code || data?.tdsCode || "DEFAULT";
  return (
    <div className="hidden w-full h-full">
      <div
        ref={printRef}
        className="p-10 min-h-screen w-full flex flex-col items-center justify-center"
      >
        <img className="max-w-xxl w-full mb-4" src={logo} alt="Space Cargo" />
        <h2 className="text-lg font-semibold text-blue-900 mb-2">
          {data.fullName}
        </h2>
        <span className="text-sm text-gray-700">{data.roomNumber}</span>
        <Barcode
          value={barcodeValue}
          format="CODE128"
          displayValue={true}
          lineColor="#000"
          width={2}
          height={40}
          textAlign="center"
          margin={10}
        />
      </div>
    </div>
  );
};

export default ParcelPrint;
