import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartments,
  reset,
} from "../../features/departments/DepartamentsSlice";
import { getEmployees } from "../../features/employees/EmployeesSlice";
import DepartmentsLayout from "../../components/layouts/DepartmentsLayout";

const Departments = () => {
  const { message, isError } = useSelector((state) => state.departments);
  const { employeesError } = useSelector((state) => state.employees);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getDepartments());
      dispatch(getEmployees());
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, message, user, isError, employeesError]);

  return <DepartmentsLayout />;
};

export default Departments;
