import Input from "./Input";

const Security = () => {
  const handleInputChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <h2 className="text-lg font-medium text-gray07">Change Password</h2>
      <div className="w-full flex flex-col items-start gap-9 mt-6">
        <Input
          text="Old password"
          onChange={handleInputChange}
          type="password"
        />
        <Input
          text="New password"
          onChange={handleInputChange}
          type="password"
        />
        <Input
          text="Confirm new password"
          onChange={handleInputChange}
          type="password"
        />
      </div>
      <button
        type="submit"
        className="bg-purple-4 text-white text-base font-normal py-2.5 px-4 rounded-full mt-9 cursor-pointer transition-all duration-500 hover:bg-purple-1"
      >
        Save changes
      </button>
    </form>
  );
};

export default Security;
