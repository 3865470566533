import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../../libs/AppContext";
import { useTranslation } from "react-i18next";
import {
  changeGroupStatus,
  getGroups,
} from "../../../features/groups/GroupsSlice";

const ChangeStatus = ({
  changeStatusForm,
  setShowChangeStatus,
  setShowResponseMessage,
  setResponseMessage,
  setGroupsIds,
  setSelectedRows,
}) => {
  const statuses = useSelector((state) => state.groups?.statuses);
  const { parcelGroupIds, status } = changeStatusForm;
  const { groupsFormData } = useAppContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getStatusName = (statusSymbol, t, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? t(status.statusDictionaryKey) : "";
  };

  const handleChangeStatus = async (e) => {
    e.preventDefault();

    const newStatus = { parcelGroupIds, status };

    try {
      const response = await dispatch(changeGroupStatus(newStatus));
      if (response.type === "parcel_group/change_status/fulfilled") {
        dispatch(getGroups(groupsFormData));
        setShowChangeStatus(false);
        setShowResponseMessage(true);
        setResponseMessage("Operation was successful");
        setGroupsIds([]);
        setSelectedRows([]);
      } else if (response.error.message === "Rejected") {
        setShowChangeStatus(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {}
  };

  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm flex justify-center items-start p-6">
      <form
        onSubmit={handleChangeStatus}
        className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mt-24"
      >
        <p className="text-lg font-semibold text-gray-900 text-center mb-2">
          You really want to move the group to status
          {getStatusName(changeStatusForm.status, t, statuses)}
        </p>
        <div className="flex justify-center items-center gap-2 mt-6">
          <button
            type="button"
            onClick={() => setShowChangeStatus(false)}
            className="flex-1 py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-200 transition"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
          >
            Change
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangeStatus;
