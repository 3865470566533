import React from "react";
import { Route, Routes as ReactRoutes, useNavigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import flattenDeep from "lodash/flattenDeep";

const generateFlattenRoutes = (routes) => {
  if (!routes) return [];
  return flattenDeep(
    routes.map(({ routes: subRoutes, ...rest }) => [
      rest,
      generateFlattenRoutes(subRoutes),
    ])
  );
};

export const renderRoutes = (mainRoutes) => {
  const Routes = ({ isAuthorized, user: authUser }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
      if (authUser.user === null) {
        navigate("/");
      }
    }, [authUser, navigate]);

    const layouts = mainRoutes.map(({ layout: Layout, routes }, index) => {
      if (typeof Layout !== "function") {
        console.error(
          `Layout is not a valid React component at index ${index}`
        );
        return null;
      }

      const subRoutes = generateFlattenRoutes(routes);

      return (
        <Route key={index} element={<Layout />}>
          <Route element={<ProtectedRoute isAuthorized={isAuthorized} />}>
            {subRoutes.map(({ component: Component, path, name }) => {
              if (typeof Component !== "function") {
                console.error(
                  `Component is not a valid React component: ${name} at path ${path}`
                );
                return null;
              }
              return (
                Component &&
                path && <Route key={name} element={<Component />} path={path} />
              );
            })}
          </Route>
        </Route>
      );
    });
    return <ReactRoutes>{layouts}</ReactRoutes>;
  };

  return Routes;
};
