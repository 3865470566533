import { Tab, TabList } from "@headlessui/react";
import { Fragment } from "react";

const navigation = [
  { name: "Account setting" },
  { name: "Security" },
  { name: "Account preferences" },
  { name: "Notifications" },
  { name: "Integrations" },
];

const ProfileNavigation = () => {
  return (
    <div className="w-full overflow-x-scroll scrollbar-hide">
      <TabList className="flex items-center justify-start gap-x-9 py-3 px-6 border-b border-gray-200 min-w-max w-full">
        {navigation?.map((item, index) => (
          <Tab as={Fragment} key={index}>
            {({ hover, selected }) => (
              <button
                className={`relative px-1 py-4 text-sm font-medium text-gray-600 transition-all duration-500 ease-in-out ${
                  hover ? "cursor-pointer text-blue-900" : ""
                } ${selected ? "text-blue-900 after:bg-purple-400" : ""} ${
                  selected || hover ? "after:w-full" : ""
                } after:absolute after:bottom-0 after:left-0 after:w-0 after:h-1`}
              >
                {item.name}
              </button>
            )}
          </Tab>
        ))}
      </TabList>
    </div>
  );
};

export default ProfileNavigation;
