import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const convertTimeToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

const getDayOfWeek = (dateString) => {
  const date = new Date(dateString);
  const dayIndex = date.getDay();
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  return daysOfWeek[dayIndex];
};

const DeliveryTimeChartSmall = ({ data }) => {
  const { t } = useTranslation();
  if (!data || data.length === 0) {
    return <div>No data</div>;
  }

  const daysOfWeek = data.map((item) => getDayOfWeek(item.withdrawDate));
  const times = data?.map((item) => convertTimeToSeconds(item.avgTime));
  const columnColor = ["rgba(124, 84, 222, 1)"];
  const series = [{ name: "", data: times }];

  const options = {
    chart: {
      type: "bar",
      height: 256,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "12px",
        distributed: true,
        borderRadius: 5,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const hours = Math.floor(val / 3600)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((val % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor(val % 60)
          .toString()
          .padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
      },
      offsetY: -20,
      style: {
        fontSize: "8px",
        colors: ["rgba(0, 0, 0, 0.6)"],
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: daysOfWeek,
      labels: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    colors: columnColor,
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          const hours = Math.floor(val / 3600)
            .toString()
            .padStart(2, "0");
          const minutes = Math.floor((val % 3600) / 60)
            .toString()
            .padStart(2, "0");
          const seconds = Math.floor(val % 60)
            .toString()
            .padStart(2, "0");
          return `${hours}:${minutes}:${seconds}`;
        },
      },
      theme: "light",
      position: "top",
      fixed: {
        enabled: false,
        position: "topRight",
      },
      style: {
        fontSize: "10px",
        colors: ["rgba(31, 45, 61, 1)"],
      },
    },
  };

  return (
    <div className="max-w-2xxl w-full bg-white-1 border border-black/5 p-4 rounded-2xl flex flex-col items-start gap-5">
      <h2 className="text-sm font-medium text-blue-5">
        {t("text.average_delivery_time")} / {data[0].changeUser}
      </h2>
      <div className="relative w-full overflow-x-scroll scrollbar-hidden">
        <Chart options={options} series={series} type="bar" height={256} />
        <div className="absolute bottom-3 left-5 w-[97%] h-px bg-gray-5/50" />
      </div>
    </div>
  );
};

export default DeliveryTimeChartSmall;
