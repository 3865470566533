import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shelfParcelsService from "./ShelfParcelsService";

const initialState = {
  shelfParcels: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getShelfParcels = createAsyncThunk(
  "shelfParcelsReport/get_report",
  async ({ reportType }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await shelfParcelsService.getShelfParcels(token, reportType);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const shelfParcelsSlice = createSlice({
  name: "shelfParcels",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShelfParcels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShelfParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.shelfParcels = action.payload;
        state.statusCode = 200;
      })
      .addCase(getShelfParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = shelfParcelsSlice.actions;
export default shelfParcelsSlice.reducer;
