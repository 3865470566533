import { useSelector } from "react-redux";
import { AppProvider } from "./libs/AppContext";
import { Routes } from "./routes";

function App() {
  const authUser = useSelector((state) => state.auth);
  return (
    <AppProvider>
      <Routes user={authUser} isAuthorized={true} />
    </AppProvider>
  );
}

export default App;
