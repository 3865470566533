import {
  AlignCenterSvg,
  AlignLeftSvg,
  AlignRightSvg,
  BoldSvg,
  ItalicSvg,
  LinkSvg,
  LowercaseSvg,
  UnderlineSvg,
  UppercaseSvg,
} from "../icons";
import upload from "../../assets/upload-picture.png";

const fonts = ["Arial", "Georgia", "Times New Roman", "Courier New"];

const TextEditorTools = ({
  setFont,
  font,
  setShowSignButton,
  showSignButton,
}) => {
  const handleCommand = (command) => {
    document.execCommand(command, false, null);
  };

  const handleLink = () => {
    const url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
  };

  const handleUppercase = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString().toUpperCase();
      range.deleteContents();
      range.insertNode(document.createTextNode(selectedText));
    }
  };

  const handleLowercase = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString().toLowerCase();
      range.deleteContents();
      range.insertNode(document.createTextNode(selectedText));
    }
  };

  const handleChangeFont = (event) => {
    setFont(event.target.value);
    document.execCommand("fontName", false, event.target.value);
  };

  return (
    <div className="flex flex-col items-start gap-4">
      <select
        onChange={handleChangeFont}
        value={font}
        className="px-4 py-1.5 bg-white border rounded-xl w-full appearance-none outline-none text-sm 2xl:text-base"
      >
        {fonts.map((font) => (
          <option key={font} value={font}>
            {font}
          </option>
        ))}
      </select>
      <div className="w-full grid grid-cols-3 gap-3">
        <button
          onClick={handleUppercase}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <UppercaseSvg />
        </button>
        <button
          onClick={handleLowercase}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <LowercaseSvg />
        </button>
        <button
          onClick={() => handleCommand("italic")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <ItalicSvg />
        </button>
        <button
          onClick={() => handleCommand("justifyLeft")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <AlignLeftSvg />
        </button>
        <button
          onClick={() => handleCommand("justifyCenter")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <AlignCenterSvg />
        </button>
        <button
          onClick={() => handleCommand("justifyRight")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <AlignRightSvg />
        </button>
        <button
          onClick={() => handleCommand("bold")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <BoldSvg />
        </button>
        <button
          onClick={handleLink}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <LinkSvg />
        </button>
        <button
          onClick={() => handleCommand("underline")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <UnderlineSvg />
        </button>
      </div>
      <button
        onClick={() => setShowSignButton(!showSignButton)}
        className="p-2.5 bg-blue-2/15 w-full rounded-xl text-xs text-blue-2"
      >
        {showSignButton ? "Remove" : "Add"} Sign Button
      </button>
      <div className="w-full p-1.5 flex justify-center items-center rounded-xl border border-dashed border-blue-300 cursor-pointer transition-all duration-300 hover:border-solid">
        <img className="max-w-20 w-full object-contain" src={upload} alt="" />
        <span className="text-sm font-medium text-blue-1">Upload Image</span>
      </div>
    </div>
  );
};

export default TextEditorTools;
