import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DashboardSvg, EmailSvg, ManagementSvg, ParcelsSvg } from "../icons";
import DropLinks from "./DropLinks";
// import { CiBoxes } from "react-icons/ci";

const NavLinks = ({ links }) => {
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState("MainPage");

  const icons = {
    AiOutlineDashboard: <DashboardSvg />,
    AiOutlineFundProjectionScreen: <ManagementSvg />,
    MdAllInbox: <ParcelsSvg />,
    null: <EmailSvg />,
  };

  useEffect(() => {
    const currentPath = window.location.pathname.slice(1);
    setActiveLink(currentPath === "MainPage" ? "MainPage" : currentPath);
  }, []);

  const handleNavClick = (link) => {
    setActiveLink(link.pageUrl);
  };

  return (
    <ul className="flex flex-col w-full items-start gap-2.5 mt-4 overflow-y-auto scrollbar-none">
      {links?.map((link, index) => (
        <li
          key={index}
          className={`flex w-full ${activeLink === link.pageUrl ? "" : ""}`}
        >
          {link.children.length > 1 ? (
            <DropLinks
              link={link}
              icons={icons}
              activeLink={activeLink}
              handleNavClick={handleNavClick}
            />
          ) : (
            <Link
              to={link.pageUrl}
              onClick={() => handleNavClick(link)}
              className="flex items-center gap-4 px-4 py-3 w-full text-gray-1 text-sm font-normal hover:text-blue-1"
            >
              {icons[link.iconUrl]} {t(link.pageDictionaryKey)}
            </Link>
          )}
        </li>
      ))}
      {/* <li>
        <Link
          to="/parcelBoxes"
          className="flex items-center gap-4 px-4 py-3 w-full text-gray-1 text-sm font-normal hover:text-blue-1"
        >
          <CiBoxes /> Parcel Boxes
        </Link>
      </li> */}
    </ul>
  );
};

export default NavLinks;
