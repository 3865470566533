import { useEffect, useRef, useState } from "react";
import { ArrowDownSvg, CheckedSvg, SearchSvg, CloseSvg } from "../icons";
import { useTranslation } from "react-i18next";

const Select = (props) => {
  const {
    options,
    updateCountryId,
    countries,
    errormark,
    employeesData,
    rolesData,
    type,
    updateUserId,
    updateRoleId,
    prevOption,
    resetSelectedOption,
    setGetBonusType,
    departmentsData,
    updateDepartmentId,
    updateFlightsId,
    flightsData,
  } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const filteredOptions = options.filter((option) =>
    option?.toLowerCase().includes(searchText.toLowerCase())
  );
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedOption(prevOption ? prevOption : null);
  }, [prevOption]);

  useEffect(() => {
    if (prevOption === null) {
      setSelectedOption(null);
    }
  }, [prevOption]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (resetSelectedOption) {
      resetSelectedOption.current = internalResetSelectedOption;
    }
  }, [resetSelectedOption]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    switch (type) {
      case "employees":
        const selectedEmployee = employeesData?.find(
          (employee) => employee.value === option
        );
        updateUserId(selectedEmployee.id);
        break;
      case "roles":
        const selectedRole = rolesData?.find((role) => role.name === option);
        updateRoleId(selectedRole.id);
        break;
      case "countries":
        const selectedCountry = countries.find(
          (country) => country.name === option
        );
        updateCountryId(selectedCountry.id);
        break;
      case "bonus":
        setGetBonusType(option);
        break;
      case "groupsDepartments":
        const selectedGroupDepartment = departmentsData?.find(
          (department) => department.address === option
        );
        updateDepartmentId(selectedGroupDepartment.id);
        break;
      case "flights":
        const selectedFlights = flightsData?.find(
          (flight) => flight.flightNumber === option
        );
        updateFlightsId(selectedFlights.id);
        break;
      default:
        break;
    }
    setIsOpen(false);
    setSearchText("");
  };

  const handleResetButtonClick = () => {
    setSelectedOption(null);
    setSearchText("");
    switch (type) {
      case "employees":
        updateUserId("");
        break;
      case "roles":
        updateRoleId("");
        break;
      case "countries":
        updateCountryId("");
        break;
      case "bonus":
        setGetBonusType("");
        break;
      case "groupsDepartments":
        updateDepartmentId("");
        break;
      case "flights":
        updateFlightsId("");
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const internalResetSelectedOption = () => {
    setSelectedOption(null);
  };

  return (
    <div ref={selectRef} className="relative w-full max-w-full">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center justify-between w-full h-14 px-4 border rounded-lg cursor-pointer ${
          errormark === "error-border" ? "border-red-500" : "border-gray-2"
        } bg-white text-gray-600`}
      >
        <div className="relative flex items-center w-full">
          <h2
            className={`transition-all duration-500 ease-in-out ${
              selectedOption ? "absolute -top-5 text-xs" : ""
            }`}
          >
            {props.text}
          </h2>
          <span className={`absolute -bottom-4 text-sm`}>{selectedOption}</span>
        </div>
        {selectedOption && (
          <div
            onClick={handleResetButtonClick}
            className="absolute right-8 top-1/2 transform -translate-y-1/2 flex items-center justify-center cursor-pointer"
          >
            <CloseSvg />
          </div>
        )}
        <div className="w-4 min-w-4 flex justify-center items-center">
          <ArrowDownSvg />
        </div>
      </div>

      {isOpen && (
        <div className="absolute top-14 w-full border rounded-lg bg-white border-gray-300 z-10 shadow-lg">
          <div className="flex items-center p-3 border-b border-gray-300">
            <SearchSvg />
            <input
              type="text"
              placeholder={t("text.search")}
              value={searchText}
              onChange={handleInputChange}
              className="w-full pl-2 text-sm text-gray-500 placeholder-gray-400 outline-none"
            />
          </div>

          <ul className="p-1 max-h-52 overflow-auto">
            {filteredOptions.length === 0 ? (
              <span className="flex justify-center items-center text-center p-4">
                No item
              </span>
            ) : (
              filteredOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  className={`flex items-center justify-between px-2 py-1 text-sm font-medium text-gray-900 cursor-pointer rounded-lg transition-all duration-300 ease-in-out hover:bg-gray-100 ${
                    selectedOption === option ? "bg-gray-200" : ""
                  }`}
                >
                  {option}
                  {selectedOption === option && <CheckedSvg />}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Select;
