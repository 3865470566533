import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import Group from "./Group";
import OneUser from "./OneUser";
import ButtonPrimary from "../buttons/ButtonPrimary";

const GroupUser = ({ formData, setFormData }) => {
  return (
    <TabGroup className="px-5 py-3 border-l border-black/10 ">
      <TabList className="grid grid-cols-[auto_auto]">
        <Tab className="border-transparent border-b-4 data-[selected]:border-blue-2 p-2 outline-none text-sm 2xl:text-base 2xl:p-4">
          Group
        </Tab>
        <Tab className="border-transparent border-b-4 data-[selected]:border-blue-2 p-2 outline-none text-sm 2xl:text-base 2xl:p-4">
          One User
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel className="py-6">
          <Group formData={formData} setFormData={setFormData} />
        </TabPanel>
        <TabPanel className="py-6">
          <OneUser formData={formData} setFormData={setFormData} />
        </TabPanel>
      </TabPanels>
      <ButtonPrimary type="submit">Sent</ButtonPrimary>
    </TabGroup>
  );
};

export default GroupUser;
