import { useTranslation } from "react-i18next";

const DeleteConfirmation = ({ handleCancel, handleDelete, text }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-black/10 backdrop-blur-sm flex justify-center items-start z-[99999999]">
      <div className="max-w-max w-full mt-24 bg-white rounded-lg flex flex-col items-center p-6">
        <p className="text-[18px] font-semibold text-gray-900 text-center mb-2">
          {t("text.confirm")} {text}?
        </p>
        <span className="text-[14px] font-normal text-gray-600 text-center">
          {t("text.confirm_desc")}
        </span>
        <div className="w-full flex justify-center items-center gap-x-1 mt-6">
          <button
            className="max-w-48 w-full text-xs font-medium rounded-lg py-2.5 px-6 border border-transparent transition-opacity ease-in-out duration-500 text-white bg-blue-2 hover:opacity-50"
            onClick={handleCancel}
          >
            {t("text.cancel")}
          </button>
          <button
            className="max-w-48 w-full text-xs font-medium rounded-lg py-2.5 px-6 border border-transparent transition-opacity ease-in-out duration-500 text-white bg-red-500 hover:opacity-50"
            onClick={handleDelete}
          >
            {t("text.delete")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
