import SelectLanguage from "./SelectLanguage";
import { useAppContext } from "../../libs/AppContext";

const AccountPreferences = () => {
  const { selectedLanguage, changeLanguage } = useAppContext();

  return (
    <form className="w-full">
      <h2 className="text-lg font-medium text-gray-600 mb-6">Language</h2>
      <SelectLanguage
        selectedLanguage={selectedLanguage}
        onSelectLanguage={changeLanguage}
      />
    </form>
  );
};

export default AccountPreferences;
