import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import employeesService from "./EmployeesService";

const initialState = {
  employees: {},
  statusCode: null,
  employeesError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getEmployees = createAsyncThunk(
  "projects/get_delegation_users",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await employeesService.getEmployees(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const registerEmployee = createAsyncThunk(
  "projects/delegation/register_user",
  async (employeeData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await employeesService.registerEmployee(employeeData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "projects/delegation/delete_user_department_link",
  async (employeeId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await employeesService.deleteEmployee(employeeId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.employees = action.payload;
        state.statusCode = 200;
      })
      .addCase(getEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.employeesError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(registerEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.employees.push(action.payload);
        state.statusCode = 200;
      })
      .addCase(registerEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.employeesError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(deleteEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departments = state.departments?.map((item) =>
          item?.users?.filter((user) => user.userId !== action.payload)
        );
        // state.departments = state.departments?.filter(
        //   (employee) => employee.userId !== action.payload
        // );
        state.statusCode = 200;
      })
      .addCase(deleteEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.employeesError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = employeesSlice.actions;
export default employeesSlice.reducer;
