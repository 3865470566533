import { Outlet } from "react-router-dom";
import Sidebar from "../components/navigation/Sidebar";

const MainLayout = () => {
  return (
    <div className="w-full min-h-screen h-full relative overflow-x-hidden md:grid lg:grid-cols-[265px_auto] xl:grid-cols-[350px_auto]">
      <Sidebar />
      <Outlet />
    </div>
  );
};

export default MainLayout;
