import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "./UsersService";

const initialState = {
  roomUsers: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//search user
export const searchUser = createAsyncThunk(
  "parcel_group/search_user",
  async (roomNumber, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await usersService.searchUser(token, roomNumber);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const roomUsers = createSlice({
  name: "roomUsers",
  initialState,
  reducers: {
    rest: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(searchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "change failed";
      });
  },
});

export const { reset } = roomUsers.actions;
export default roomUsers.reducer;
