import noData from "../../assets/no-data.svg";
import noDataBg from "../../assets/no-data-bg.svg";

const NoDataComponent = () => {
  return (
    <div className="h-full w-full px-5 relative">
      <img
        src={noData}
        alt="no data"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-32 w-full object-contain md:h-52"
      />
      <img className="pt-10 h-full" src={noDataBg} alt="countries" />
    </div>
  );
};

export default NoDataComponent;
