import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import departmentsService from "./DepartmentsService";

const initialState = {
  departments: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getDepartments = createAsyncThunk(
  "projects/delegation/get_departments",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await departmentsService.getDepartments(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const registerDepartment = createAsyncThunk(
  "projects/register_department",
  async (departmentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await departmentsService.registerDepartment(departmentData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "projects/delegation/change_department",
  async (updatedDepartmentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await departmentsService.updateDepartment(
        updatedDepartmentData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "projects/delegation/delete_department",
  async (departmentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await departmentsService.deleteDepartment(departmentId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departments = action.payload;
        state.statusCode = 200;
      })
      .addCase(getDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(registerDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departments.push(action.payload);
        state.statusCode = 200;
      })
      .addCase(registerDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(updateDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.departments.findIndex(
          (department) => department.id === action.payload.id
        );
        state.departments[index] = action.payload;
        state.statusCode = 200;
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(deleteDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departments = state.departments?.filter(
          (department) => department.id !== action.payload
        );
        state.statusCode = 200;
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = departmentsSlice.actions;
export default departmentsSlice.reducer;
