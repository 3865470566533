import axios from "axios";

const getShelfParcels = async (token, reportType) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const shelfParcelsService = {
  getShelfParcels,
};

export default shelfParcelsService;
