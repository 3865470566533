import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { DashboardArrowDownSvg } from "../icons";
import { useAppContext } from "../../libs/AppContext";

const languages = [
  { code: "en", name: "United States - English" },
  { code: "ge", name: "Georgia - Georgian" },
];

const SelectLanguage = ({ selectedLanguage, onSelectLanguage }) => {
  const { changeLanguage } = useAppContext();

  const handleChange = (selectedLanguage) => {
    onSelectLanguage(selectedLanguage);
    changeLanguage(selectedLanguage);
  };

  return (
    <div className="relative max-w-[467px] w-full">
      <Listbox value={selectedLanguage} onChange={handleChange}>
        <ListboxButton className="flex items-center justify-between p-4 text-gray-1 text-base font-normal bg-white-1 border border-black/5 rounded-md w-full cursor-pointer">
          {selectedLanguage ? selectedLanguage.name : "Select Language"}
          <DashboardArrowDownSvg className="w-4" />
        </ListboxButton>
        <ListboxOptions className="absolute w-full right-0 left-auto top-[calc(100%+10px)] border border-purple-2 rounded-md shadow-md overflow-hidden z-[9999]">
          {languages.map((item, index) => (
            <ListboxOption
              key={index}
              value={item}
              className="text-gray-1 text-base font-normal p-2 px-4 transition-colors duration-500 border-b border-black/5 hover:bg-purple-2/20 cursor-pointer"
            >
              {item.name}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </div>
  );
};

export default SelectLanguage;
