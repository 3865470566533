import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notCustomerTypesService from "./NotCustomerTypesService";

const initialState = {
  notificationCustomerTypes: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getNotCustomerTypes = createAsyncThunk(
  "notification/get_notification_customer_types",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await notCustomerTypesService.getNotCustomerTypes(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const notTypesSlice = createSlice({
  name: "notCustomerTypes",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotCustomerTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotCustomerTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notificationCustomerTypes = action.payload;
        state.statusCode = 200;
      })
      .addCase(getNotCustomerTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = notTypesSlice.actions;
export default notTypesSlice.reducer;
