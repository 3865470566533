import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import parcelGroupsService from "./ParcelGroupService";

const initialState = {
  notificationParcelGroups: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getParcelGroups = createAsyncThunk(
  "notification/parcel_group/search_parcel_group",
  async (barcode, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await parcelGroupsService.getPacelGroups(token, barcode);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const notificationParcelGroupsSlice = createSlice({
  name: "notParcelGroups",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParcelGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getParcelGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notificationParcelGroups = action.payload;
        state.statusCode = 200;
      })
      .addCase(getParcelGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = notificationParcelGroupsSlice.actions;
export default notificationParcelGroupsSlice.reducer;
