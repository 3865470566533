import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BackSvg } from "../../components/icons";
import { Link } from "react-router-dom";
import NotificationsLayout from "./NotificationsLayout";
import ContentTemplate from "../../components/notifications/ContentTemplate";
import TextArea from "../../components/notifications/TextArea";
import GroupUser from "../../components/notifications/GroupUser";

const CreateNotification = () => {
  const { t } = useTranslation();
  const [showSignButton, setShowSignButton] = useState(false);
  const [font, setFont] = useState("Arial");
  const [formData, setFormData] = useState({
    scheduleDate: "",
    notificationDetails: {
      GE: {
        title: "",
        message: "",
      },
      EN: {
        title: "",
        message: "",
      },
    },
    notificationTypes: [],
    notificationCustomerTypes: [],
    parcelGroupBarcodes: [],
    userRoomNumbers: [],
    emails: [],
    mobiles: [],
    onlyDeclared: "",
  });

  const handleCreateNotification = async (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <NotificationsLayout>
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to="/notifications"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11 cursor-pointer"
          >
            <BackSvg />
          </Link>
          <div className="text-xl font-semibold text-gray-800">
            შექმენი {t("text.notifications")}
          </div>
        </div>
      </div>
      <form
        onSubmit={handleCreateNotification}
        className="h-[calc(100%-77px)] grid lg:grid-cols-[170px_auto_253px] xl:grid-cols-[240px_auto_303px] 2xl:grid-cols-[290px_auto_353px]"
      >
        <ContentTemplate
          setShowSignButton={setShowSignButton}
          showSignButton={showSignButton}
          font={font}
          setFont={setFont}
        />
        <TextArea
          showSignButton={showSignButton}
          font={font}
          formData={formData}
          setFormData={setFormData}
        />
        <GroupUser formData={formData} setFormData={setFormData} />
      </form>
    </NotificationsLayout>
  );
};

export default CreateNotification;
