import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import groupsService from "./GroupsService";

const initialState = {
  groups: {},
  countries: [],
  departments: [],
  statuses: [],
  flights: [],
  parcels: [],
  newParcelInfo: [],
  categoriesWithMaster: [],
  parcelServices: [],
  rate: "",
  barcode: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// get countries
export const getCountries = createAsyncThunk(
  "/parcel_group/get_working_departments",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getCounties(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// get departments
export const getDepartments = createAsyncThunk(
  "parcel_group/get_departments",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getDepartments(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// get flights
export const getFlights = createAsyncThunk(
  "parcel_group/get_flights",
  async (warehouseCountryId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getFlights(token, warehouseCountryId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// get statuses
export const getStatuses = createAsyncThunk(
  "parcel_group/get_parcel_group_statuses",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getStatuses(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// get groups
export const getGroups = createAsyncThunk(
  "/parcel_group/get_parcel_groups",
  async (
    { warehouseCountryId, flightNumber, status, barcode, page, perPage },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getGroups(
        token,
        warehouseCountryId,
        flightNumber,
        status,
        barcode,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// register group
export const registerGroup = createAsyncThunk(
  "parcel_group/parcel_group_registration",
  async (groupData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.registerGroup(groupData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// update group
export const updateGroup = createAsyncThunk(
  "parcel_group/parcel_group_change",
  async (updatedGroupData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.updateGroup(updatedGroupData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// delete groups
export const deleteGroup = createAsyncThunk(
  "parcel_group/parcel_group_delete",
  async (parcelGroupIds, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.deleteGroup(parcelGroupIds, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        //
      });
    }
  }
);

// change rate
export const changeRate = createAsyncThunk(
  "parcel_group/change_ccy_rate",
  async (rateData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.changeRate(rateData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// get barcode
export const getBarcode = createAsyncThunk(
  "parcel_group/get_barcode",
  async (barcode, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getBarcode(token, barcode);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// export parcels
export const exportParcels = createAsyncThunk(
  "parcel_group/export_parcels",
  async ({ parcelGroupId, language }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.exportParcels(parcelGroupId, token, language);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// export usa parcels
export const exportUsaParcels = createAsyncThunk(
  "parcel_group/export_usa_parcels",
  async ({ parcelGroupId, language }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.exportUsaParcels(
        parcelGroupId,
        token,
        language
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// send email
export const sendEmail = createAsyncThunk(
  "parcel_group/send_email",
  async (emailData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.sendEmail(emailData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// send sms
export const sendSms = createAsyncThunk(
  "parcel_group/send_sms",
  async (smsData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.sendSms(smsData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// change status
export const changeGroupStatus = createAsyncThunk(
  "parcel_group/change_status",
  async ({ parcelGroupIds, status }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      await groupsService.changeGroupStatus(parcelGroupIds, status, token);
      return { parcelGroupIds, status };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// get parcels
export const getParcels = createAsyncThunk(
  "/parcel_group/get_parcels",
  async ({ parcelGroupId, tdsCode, roomNumber, page, perPage }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getParcels(
        token,
        parcelGroupId,
        tdsCode,
        roomNumber,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// get new parcels information
export const getNewParcelInfo = createAsyncThunk(
  "parcel_group/get_new_parcel_info",
  async (tds_code, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getNewParcelInfo(token, tds_code);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

//get categories with master
export const getCategoriesWithMaster = createAsyncThunk(
  "parcel/get_categories_with_master",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getCategoriesWithMaster(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

//receive new parcel
export const receiveParcelNew = createAsyncThunk(
  "parcel_group/receive_parcel_new",
  async (parcelData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.receiveParcelNew(token, parcelData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

//update new parcel
export const updateParcelNew = createAsyncThunk(
  "parcel_group/change_parcel_new",
  async (parcelData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.updateParcelNew(token, parcelData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

//get parcel services
export const getParcelServices = createAsyncThunk(
  "parcel_group/get_parcel_services",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getParcelServices(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    rest: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDepartments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departments = action.payload;
      })
      .addCase(getDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStatuses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.statuses = action.payload;
      })
      .addCase(getStatuses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFlights.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFlights.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.flights = action.payload;
      })
      .addCase(getFlights.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.groups = action.payload;
      })
      .addCase(getGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(registerGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.groups?.parcelGroups.unshift(action.payload);
      })
      .addCase(registerGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.groups?.parcelGroups.findIndex(
          (group) => group.id === action.payload.id
        );
        state.groups[index] = action.payload;
      })
      .addCase(updateGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const groupIdsToDelete = action.payload?.parcelGroupIds || [];

        if (state.groups && state.groups?.parcelGroups) {
          state.groups = {
            ...state.groups,
            parcelGroups: state.groups?.parcelGroups?.map((group) => {
              return {
                ...group,
                groups: (group?.groups?.parcelGroups || []).filter(
                  (subGroup) =>
                    !groupIdsToDelete.includes(subGroup.parcelGroupId)
                ),
              };
            }),
          };
        }
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(changeRate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeRate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rate = action.payload;
      })
      .addCase(changeRate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "change failed";
      })
      .addCase(getBarcode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBarcode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.barcode = action.payload;
      })
      .addCase(getBarcode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(exportParcels.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(exportParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(exportParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "Export failed";
      })
      .addCase(exportUsaParcels.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(exportUsaParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(exportUsaParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "Export failed";
      })
      .addCase(sendEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "Send failed";
      })
      .addCase(sendSms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendSms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(sendSms.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "Send failed";
      })
      .addCase(changeGroupStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeGroupStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const { parcelGroupIds, status } = action.payload;
        state?.groups?.parcelGroups.forEach((group) => {
          if (parcelGroupIds.includes(group.id)) {
            group.status = status;
          }
        });
      })
      .addCase(changeGroupStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getParcels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.parcels = action.payload;
      })
      .addCase(getParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getNewParcelInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNewParcelInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newParcelInfo = action.payload;
      })
      .addCase(getNewParcelInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCategoriesWithMaster.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategoriesWithMaster.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categoriesWithMaster = action.payload;
      })
      .addCase(getCategoriesWithMaster.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "change failed";
      })
      .addCase(receiveParcelNew.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(receiveParcelNew.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.parcels?.parcels.unshift(action.payload);
      })
      .addCase(receiveParcelNew.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateParcelNew.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateParcelNew.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.parcels.parcels.findIndex(
          (parcel) => parcel.id === action.payload.id
        );
        state.parcels.parcels[index] = action.payload;
      })
      .addCase(updateParcelNew.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getParcelServices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getParcelServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.parcelServices = action.payload;
      })
      .addCase(getParcelServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = groupsSlice.actions;
export default groupsSlice.reducer;
