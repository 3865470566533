import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCategories,
  registerCategory,
} from "../../../features/categories/CategoriesSlice";
import Input from "../../common/Input";
import Select from "../../common/Select";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";

const CategoriesModal = ({
  setResponseMessage,
  setShowResponseMessage,
  setShowModal,
  closeModal,
}) => {
  const [getBonusType, setGetBonusType] = useState("");
  const [formData, setFormData] = useState({
    categoryName: "",
    bonusType: "",
    bonusValue: "",
  });
  const [errors, setErrors] = useState({
    categoryName: "",
    bonusType: "",
    bonusValue: "",
  });
  const resetSelectedOptionRef = useRef(null);
  const { categoryName, bonusType, bonusValue } = formData;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const options = ["fixed", "percentage"];

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      bonusType: getBonusType,
    }));
  }, [getBonusType]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!categoryName || !bonusType || !bonusValue) {
      setErrors({
        categoryName: categoryName ? "" : `${t("text.category_required")}`,
        bonusType: bonusType ? "" : `${t("text.bonus_type_required")}`,
        bonusValue: bonusValue ? "" : `${t("text.bonus_value_required")}`,
      });
      return;
    }

    const categoryData = {
      categoryName,
      bonusType,
      bonusValue,
    };

    try {
      const response = await dispatch(registerCategory(categoryData));

      if (response.payload.type === "success") {
        dispatch(getCategories());
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        setFormData({
          categoryName: "",
          bonusType: "",
          bonusValue: "",
        });
        if (resetSelectedOptionRef.current) {
          resetSelectedOptionRef.current();
        }
      }

      if (response.error.message === "Rejected") {
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form
      onSubmit={handleRegister}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline text={t("text.add_category")} onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.name")}
              name="categoryName"
              value={formData.categoryName}
              onchange={handleInputChange}
              error={errors.categoryName}
              errormark={errors.categoryName ? "error-border" : ""}
            />
            {errors.categoryName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.categoryName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text={t("text.bonusType")}
              options={options}
              value={formData.bonusType}
              type="bonus"
              setGetBonusType={setGetBonusType}
              resetSelectedOption={resetSelectedOptionRef}
              error={errors.bonusType}
              errormark={errors.bonusType ? "error-border" : ""}
            />
            {errors.bonusType && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.bonusType}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.bonusValue")}
              name="bonusValue"
              value={formData.bonusValue}
              onchange={handleInputChange}
              error={errors.bonusValue}
              errormark={errors.bonusValue ? "error-border" : ""}
            />
            {errors.bonusValue && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.bonusValue}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit" className="mt-4">
          {t("text.add")}
        </ButtonDefault>
      </div>
    </form>
  );
};

export default CategoriesModal;
