import React from "react";
import ParcelBoxesPropertisBar from "../table/parcelBoxes/ParcelBoxesPropertisBar";

const ParcelBoxesLayout = () => {
  return (
    <>
      <ParcelBoxesPropertisBar />
    </>
  );
};

export default ParcelBoxesLayout;
