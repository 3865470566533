import axios from "axios";

const getIssuedParcels = async (
  token,
  reportType,
  groupPeriod,
  departmentId
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const getPeriods = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_periods`,
    config
  );

  return response.data;
};

const getIssuedParcelsDetails = async (
  token,
  reportType,
  groupPeriod,
  departmentId,
  page,
  perPage,
  userName,
  tdsCode,
  withdrawStartDate,
  withdrawEndDate,
  rate,
  inpStartDate,
  inpEndDate,
  status
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
    page: page,
    perPage: perPage,
    userName: userName,
    tdsCode: tdsCode,
    withdrawStartDate: withdrawStartDate,
    withdrawEndDate: withdrawEndDate,
    rate: rate,
    inpStartDate: inpStartDate,
    inpEndDate: inpEndDate,
    status: status,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report_details`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const exportExcel = async (
  token,
  reportType,
  groupPeriod,
  departmentId,
  page,
  perPage,
  userName,
  tdsCode,
  withdrawStartDate,
  withdrawEndDate,
  rate,
  inpStartDate,
  inpEndDate,
  status
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
    page: page,
    perPage: perPage,
    userName: userName,
    tdsCode: tdsCode,
    withdrawStartDate: withdrawStartDate,
    withdrawEndDate: withdrawEndDate,
    rate: rate,
    inpStartDate: inpStartDate,
    inpEndDate: inpEndDate,
    status: status,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report_details_excel`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const issuedParcelsService = {
  getIssuedParcels,
  getPeriods,
  getIssuedParcelsDetails,
  exportExcel,
};

export default issuedParcelsService;
