import { FaMinus } from "react-icons/fa6";

const CheckAll = ({ checked, onChange }) => {
  return (
    <label
      className={`relative flex h-4 w-4 min-w-4 rounded border border-gray-2 cursor-pointer ${
        checked ? " border-transparent bg-purple-2" : "bg-white"
      }`}
      htmlFor="checkbox"
    >
      <input
        type="checkbox"
        className="hidden"
        id="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {checked && (
        <span className="absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-2.5 h-3.5 flex justify-center items-center">
          <FaMinus />
        </span>
      )}
    </label>
  );
};

export default CheckAll;
