import { useState, useEffect, useRef } from "react";
import { DeleteSvg } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import { registerEmployee } from "../../../features/employees/EmployeesSlice";
import { getDepartments } from "../../../features/departments/DepartamentsSlice";
import { useTranslation } from "react-i18next";
import {
  deleteDepartmentLinks,
  getDepartmentLinks,
} from "../../../features/departmentLinks/DepartmentLinksSlice";
import ButtonDefault from "../../buttons/ButtonDefault";
import Select from "../../common/Select";
import ModalsHeadline from "../components/ModalsHeadline";

const AddEmployee = ({
  handleShowAddEmployee,
  departmentID,
  setShowAddEmployee,
  setResponseMessage,
  setShowResponseMessage,
}) => {
  const { roles } = useSelector((state) => state.roles);
  const { employees } = useSelector((state) => state.employees);
  const { departmentLinks } = useSelector((state) => state.departmentLinks);
  const { departments } = useSelector((state) => state.departments);
  const [combinedData, setCombinedData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [formData, setFormData] = useState({
    departmentId: departmentID,
    userid: "",
    roleId: "",
  });
  const [errors, setErrors] = useState({
    userid: "",
    roleId: "",
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const resetSelectedOptionRefEmployees = useRef(null);
  const resetSelectedOptionRefRoles = useRef(null);
  const { departmentId, userid, roleId } = formData;

  useEffect(() => {
    if (departmentID) {
      const getRoles = roles?.map((role) => role.name);
      setRoleOptions(getRoles);
    }
  }, [departmentID, roles]);

  useEffect(() => {
    if (departmentID) {
      const getEmployees = employees.map((employee) => employee.value);
      setEmployeesOptions(getEmployees);
    }
  }, [departmentID, employees]);

  useEffect(() => {
    const combined = departments.map((department) => {
      const linksForDepartment = departmentLinks.filter(
        (link) => link.departmentId === department.id
      );
      return {
        ...department,
        links: linksForDepartment,
      };
    });

    setCombinedData(combined);
  }, [departments, departmentLinks]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      departmentId: departmentID,
    }));
  }, [departmentID]);

  useEffect(() => {
    const filteredEmployees = combinedData.filter(
      (employee) => employee.id === departmentID
    );

    setDepartmentData(filteredEmployees);
  }, [combinedData, departmentID]);

  const updateUserId = (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      userid: id,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      userid: "",
    }));
  };

  const updateRoleId = (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      roleId: id,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      roleId: "",
    }));
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();

    if (!userid || !roleId) {
      setErrors({
        userid: userid ? "" : `${t("text.employee_required")}`,
        roleId: roleId ? "" : `${t("text.role_required")}`,
      });
      return;
    }

    const employeeData = {
      departmentId,
      userid,
      roleId,
    };

    try {
      const response = await dispatch(registerEmployee(employeeData));
      if (response.payload.type === "success") {
        dispatch(getDepartments());
        setShowAddEmployee(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        resetSelectedOptionRefEmployees.current?.();
        resetSelectedOptionRefRoles.current?.();
      }

      if (response.error.message === "Rejected") {
        setShowAddEmployee(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          userid: error.response.data.userid || "",
          roleId: error.response.data.roleId || "",
        });
      }
    }
  };

  const handleDeleteEmployee = async (employeeId) => {
    const departmentLink = departmentLinks?.find(
      (link) => link.id === employeeId
    );

    if (departmentLink?.id === employeeId) {
      const employeeData = { id: departmentLink?.id };
      try {
        const response = await dispatch(deleteDepartmentLinks(employeeData));
        if (response.payload.type === "success") {
          dispatch(getDepartments());
          dispatch(getDepartmentLinks());
          setShowResponseMessage(true);
          setResponseMessage(response.payload.message);
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <ModalsHeadline
        text={t("text.add_employee")}
        onClick={handleShowAddEmployee}
      />
      <form onSubmit={handleAddEmployee} className="p-6 flex flex-col gap-6">
        <div className="w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Select
              text={t("text.select_employee")}
              options={employeesOptions}
              employeesData={employees}
              updateUserId={updateUserId}
              resetSelectedOption={resetSelectedOptionRefEmployees}
              type="employees"
              error={errors.userid}
              errormark={errors.userid ? "error-border" : ""}
            />
            {errors.userid && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.userid}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text={t("text.select_role")}
              options={roleOptions}
              rolesData={roles}
              updateRoleId={updateRoleId}
              resetSelectedOption={resetSelectedOptionRefRoles}
              type="roles"
              error={errors.roleId}
              errormark={errors.roleId ? "error-border" : ""}
            />
            {errors.roleId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.roleId}
              </p>
            )}
          </div>
        </div>
        <ButtonDefault type="submit">{t("text.add")}</ButtonDefault>
      </form>
      <div className="p-6">
        {departmentData?.map((employee, index) => (
          <div
            key={index}
            className="w-full flex flex-col gap-3 border-t border-gray-300 pt-6"
          >
            {employee?.users?.map((user, index) => (
              <div
                key={index}
                className="w-full flex items-center justify-between bg-gray-100 rounded-lg p-3"
              >
                <div className="flex flex-col items-start">
                  <span className="text-xs font-medium text-gray-500">
                    {user.role}
                  </span>
                  <h2 className="text-sm font-medium text-gray-800">
                    {user.user}
                  </h2>
                </div>
                <button onClick={() => handleDeleteEmployee(user.id)}>
                  <DeleteSvg />
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default AddEmployee;
