import { TabGroup, TabPanels, TabPanel } from "@headlessui/react";
import ProfileNavigation from "../navigation/ProfileNavigation";
import AccountSetting from "../profile/AccountSetting";
import Security from "../profile/Security";
import AccountPreferences from "../profile/AccountPreferences";
import Notifications from "../profile/Notifications";
import Integrations from "../profile/Integrations";

const tabs = [
  { component: AccountSetting },
  { component: Security },
  { component: AccountPreferences },
  { component: Notifications },
  { component: Integrations },
];

const ProfileLayout = () => {
  return (
    <TabGroup className="w-full">
      <ProfileNavigation />
      <TabPanels className="p-8 md:p-6">
        {tabs.map(
          ({ component: Component }, index) =>
            Component && (
              <TabPanel key={index}>
                <Component />
              </TabPanel>
            )
        )}
      </TabPanels>
    </TabGroup>
  );
};

export default ProfileLayout;
