import axios from "axios";

const getPacelGroups = async (token, barcode) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/search_parcel_group`,
    { barcode },
    config
  );

  return response.data;
};

const parcelGroupsService = { getPacelGroups };
export default parcelGroupsService;
