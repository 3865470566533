import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackSvg, ExportFileSvg2 } from "../../../components/icons";
import {
  getIssuedParcelsDetails,
  exportExcel,
} from "../../../features/dashboard/issuedParcels/IssuedParcelsSlice";
import InnerPagesPeriods from "../../../components/dashboard/components/InnerPagesPeriods";
import InnerPagesBranches from "../../../components/dashboard/components/InnerPagesBranches";
import IssuedParcelsTable from "../../../components/table/issuedParcels/IssuedParcelsTable";
import { getPeriods } from "../../../features/dashboard/periods/PeriodsSlice";
import { getBranches } from "../../../features/dashboard/branches/BranchesSlice";

const IssuedParcelsDetails = () => {
  const [formData, setFormData] = useState({
    reportType: "terminalRequests",
    groupPeriod: "",
    departmentId: "",
    page: 1,
    perPage: 10,
    userName: "",
    tdsCode: "",
    withdrawStartDate: null,
    withdrawEndDate: null,
    rate: "",
    inpStartDate: null,
    inpEndDate: null,
    status: "",
  });
  const {
    reportType,
    groupPeriod,
    departmentId,
    page,
    perPage,
    userName,
    tdsCode,
    withdrawStartDate,
    withdrawEndDate,
    rate,
    inpStartDate,
    inpEndDate,
    status,
  } = formData;

  const { terminalRequestsDetails } = useSelector(
    (state) => state.issuedParcels
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (groupPeriod) {
      dispatch(
        getIssuedParcelsDetails({
          reportType,
          groupPeriod,
          departmentId,
          page,
          perPage,
          userName,
          tdsCode,
          withdrawStartDate,
          withdrawEndDate,
          rate,
          inpStartDate,
          inpEndDate,
          status,
        })
      );
    }
  }, [
    dispatch,
    reportType,
    groupPeriod,
    departmentId,
    page,
    perPage,
    userName,
    tdsCode,
    withdrawStartDate,
    withdrawEndDate,
    rate,
    inpStartDate,
    inpEndDate,
    status,
  ]);

  useEffect(() => {
    dispatch(getPeriods());
    dispatch(getBranches());
  }, [dispatch]);

  const handleExport = async () => {
    try {
      const action = await dispatch(
        exportExcel({
          reportType,
          groupPeriod,
          departmentId,
          page,
          perPage,
          withdrawStartDate,
          withdrawEndDate,
          inpStartDate,
          inpEndDate,
          status,
        })
      );
      const { file, fileName } = action.payload.message;
      const decodedData = atob(file);
      const byteArray = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const handlePageIncrement = () => {
    setFormData((prevData) => ({ ...prevData, page: page + 1 }));
  };

  const handlePageDecrement = () => {
    if (page > 1) {
      setFormData((prevData) => ({ ...prevData, page: page - 1 }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setFormData((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setFormData((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <div>
      <div className="w-full bg-gray-100 p-3 flex items-start justify-between border-b gap-2 border-gray-300 relative z-10 flex-col md:flex-row md:items-center md:gap-4">
        <div className="flex items-center justify-start gap-3">
          <Link
            to="/MainPage"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          <div className="text-lg font-semibold text-gray-800 md:text-xl">
            {t("text.issued_parcels")}
          </div>
        </div>
        <div className="w-full flex items-start gap-2 relative z-50 flex-col md:w-max md:flex-row md:items-center md:gap-4">
          <InnerPagesPeriods setFormData={setFormData} />
          <InnerPagesBranches setFormData={setFormData} />
        </div>
      </div>
      <div className="w-full bg-gray-100 p-3 flex items-center justify-end border-b border-gray-300 relative z-5">
        <div
          className="flex items-center gap-2 p-2 bg-white border border-gray-300 rounded-md text-sm font-medium text-purple-1 cursor-pointer"
          onClick={handleExport}
        >
          {t("text.export")}
          <ExportFileSvg2 />
        </div>
      </div>
      <IssuedParcelsTable
        data={terminalRequestsDetails?.result}
        onNextPage={handlePageIncrement}
        onPrevPage={handlePageDecrement}
        handlePageClick={handlePageClick}
        handlePerPage={handlePerPage}
        currentPage={page}
        perPage={perPage}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
};

export default IssuedParcelsDetails;
