import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CitySvg,
  DistrictSvg,
  DocumentSvg,
  EmailSvg,
  ExportFileSvg,
  FaceIDSvg,
  LocationSvg,
  PhoneSvg,
  UserSvg2,
  WorldSvg,
} from "../../icons";

import ModalsHeadline from "../components/ModalsHeadline";

const ClientInformation = ({ handleShowClientInfo, clientId, data }) => {
  const [clientInfo, setClientInfo] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const filteredClient = data.find((client) => client.id === clientId);
    setClientInfo(filteredClient);
  }, [clientId, data]);

  return (
    <>
      <ModalsHeadline text="Client Details" onClick={handleShowClientInfo} />
      <div className="w-full p-6 flex flex-col gap-4">
        <div className="border-b border-gray-200 py-2 flex items-center gap-4">
          <FaceIDSvg />
          <div className="flex flex-col">
            <h2 className="text-sm font-normal text-gray-600">
              {t("text.inn")}
            </h2>
            <span className="text-base font-medium text-gray-900">
              {clientInfo?.inn}
            </span>
          </div>
        </div>
        <div className="border-b border-gray-200 py-2 flex items-center gap-4">
          <UserSvg2 />
          <div className="flex flex-col">
            <h2 className="text-sm font-normal text-gray-600">
              {t("text.user")}
            </h2>
            <span className="text-base font-medium text-gray-900">
              {clientInfo?.fullName}
            </span>
          </div>
        </div>
        <div className="border-b border-gray-200 py-2 flex items-center gap-4">
          <EmailSvg />
          <div className="flex flex-col">
            <h2 className="text-sm font-normal text-gray-600">
              {t("text.email")}
            </h2>
            <span className="text-base font-medium text-gray-900">
              {clientInfo?.email}
            </span>
          </div>
        </div>
        <div className="border-b border-gray-200 py-2 flex items-center gap-4">
          <WorldSvg />
          <div className="flex flex-col">
            <h2 className="text-sm font-normal text-gray-600">
              {t("text.country")}
            </h2>
            <span className="text-base font-medium text-gray-900">
              {clientInfo?.country}
            </span>
          </div>
        </div>
        <div className="border-b border-gray-200 py-2 flex items-center gap-4">
          <CitySvg />
          <div className="flex flex-col">
            <h2 className="text-sm font-normal text-gray-600">
              {t("text.city")}
            </h2>
            <span className="text-base font-medium text-gray-900">
              {clientInfo?.cityName}
            </span>
          </div>
        </div>
        <div className="border-b border-gray-200 py-2 flex items-center gap-4">
          <DistrictSvg />
          <div className="flex flex-col">
            <h2 className="text-sm font-normal text-gray-600">
              {t("text.district")}
            </h2>
            <span className="text-base font-medium text-gray-900">
              {clientInfo?.districtName}
            </span>
          </div>
        </div>
        <div className="border-b border-gray-200 py-2 flex items-center gap-4">
          <PhoneSvg />
          <div className="flex flex-col">
            <h2 className="text-sm font-normal text-gray-600">
              {t("text.mobile")}
            </h2>
            <span className="text-base font-medium text-gray-900">
              {clientInfo?.phone}
            </span>
          </div>
        </div>
        <div className="border-b border-gray-200 py-2 flex items-center gap-4">
          <LocationSvg />
          <div className="flex flex-col">
            <h2 className="text-sm font-normal text-gray-600">
              {t("text.address")}
            </h2>
            <span className="text-base font-medium text-gray-900">
              {clientInfo?.address}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {clientInfo?.attachments?.map((file, index) => (
            <div
              key={index}
              className="w-full bg-gray-100 p-3 rounded-md flex items-center justify-between gap-3"
            >
              <div className="flex items-center gap-3">
                <span className="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center">
                  <DocumentSvg />
                </span>
                <div className="flex flex-col">
                  <h3 className="text-sm font-medium text-gray-900 truncate">
                    {file.name}
                  </h3>
                </div>
              </div>
              <div className="flex items-center">
                <Link
                  to={file.url}
                  target="_blank"
                  className="p-2 hover:text-blue-600 transition-colors"
                >
                  <ExportFileSvg />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ClientInformation;
