import { useEffect, useState } from "react";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ArrowDownSvg } from "../icons";
import { useTranslation } from "react-i18next";

const perPages = [
  { id: 1, value: 8 },
  { id: 2, value: 10 },
  { id: 3, value: 20 },
  { id: 4, value: 50 },
  { id: 5, value: 100 },
];

const Pagination = ({
  currentPage,
  onNextPage,
  onPrevPage,
  handlePerPage,
  recordsNumber,
  perPage,
  handlePageClick,
}) => {
  const [selectedPerPages, setSelectedPerPages] = useState(perPages[1]);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    if (recordsNumber) {
      const total = Math.ceil(recordsNumber / perPage);

      setTotalPages(total);
    }
  }, [perPage, recordsNumber]);

  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 3;

    const calculateRange = (currentPage, totalPages, maxVisiblePages) => {
      const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
      let start = currentPage - halfMaxVisiblePages;
      let end = currentPage + halfMaxVisiblePages;

      if (start < 1) {
        start = 1;
        end = Math.min(totalPages, maxVisiblePages);
      } else if (end > totalPages) {
        end = totalPages;
        start = Math.max(1, totalPages - maxVisiblePages + 1);
      }

      return { start, end };
    };

    const { start, end } = calculateRange(
      currentPage,
      totalPages,
      maxVisiblePages
    );

    if (start > 1) {
      pages.push(
        <button
          key={1}
          onClick={() => handlePageClick(1)}
          className="w-9 h-9 flex items-center justify-center bg-gray-200 rounded-full text-sm font-medium text-gray-800 hover:bg-purple-2 hover:text-white transition duration-300"
        >
          1
        </button>
      );
      if (start > 2) {
        pages.push(
          <span
            key="ellipsis1"
            className="w-9 h-9 flex items-center justify-center text-gray-500"
          >
            ...
          </span>
        );
      }
    }

    for (let i = start; i <= end; i++) {
      pages.push(
        <button
          key={i}
          className={`w-9 h-9 flex items-center justify-center rounded-full text-sm font-medium ${
            i === currentPage
              ? "bg-purple-2 text-white"
              : "bg-gray-200 text-gray-800 hover:bg-purple-2 hover:text-white"
          } transition duration-300`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        pages.push(
          <span
            key="ellipsis2"
            className="w-9 h-9 flex items-center justify-center text-gray-500"
          >
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
          className="w-9 h-9 flex items-center justify-center bg-gray-200 rounded-full text-sm font-medium text-gray-800 hover:bg-purple-2 hover:text-white transition duration-300"
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="w-full flex items-center justify-center gap-2 p-6">
      <button
        onClick={onPrevPage}
        disabled={currentPage === 1}
        className="w-9 h-9 flex items-center justify-center bg-gray-200 rounded-full text-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <SlArrowLeft />
      </button>
      {renderPageNumbers()}
      <button
        onClick={() => onNextPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="w-9 h-9 flex items-center justify-center bg-gray-200 rounded-full text-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <SlArrowRight />
      </button>
      {recordsNumber > 100 && (
        <div className="relative w-max">
          <Listbox value={selectedPerPages} onChange={setSelectedPerPages}>
            <ListboxButton className="w-full h-9 px-3 flex items-center justify-between bg-transparent border border-gray-200 rounded-full text-gray-600">
              {selectedPerPages.value} {t("text.records")} / {t("text.page")}
              <ArrowDownSvg />
            </ListboxButton>
            <ListboxOptions className="absolute bottom-full w-full bg-white border border-gray-200 rounded-3xl shadow-md mt-1">
              {perPages.map((page) => (
                <ListboxOption
                  key={page.id}
                  value={page}
                  as="button"
                  className="w-full px-3 py-1.5 text-gray-600 hover:bg-blue-100"
                  onClick={() => handlePerPage(page.value)}
                >
                  {page.value} {t("text.records")} / {t("text.page")}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </div>
      )}
    </div>
  );
};

export default Pagination;
