import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMainModal, setShowMainModal] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [groupsFormData, setGroupsFormData] = useState({
    warehouseCountryId: "",
    flightNumber: "",
    status: "",
    barcode: "",
    page: 1,
    perPage: 10,
  });
  const [matchingPermission, setMatchingPermission] = useState(null);
  const [isPermission, setIsPermission] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [groupsCountryId, setGroupsCountryId] = useState("");
  const [groupsIds, setGroupsIds] = useState([]);
  const [globalPeriod, setGlobalPeriod] = useState("");
  const [parcelGroupId, setParcelGroupId] = useState("");
  const [parcelId, setParcelId] = useState("");
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const { links } = useSelector((state) => state.menu);

  useEffect(() => {
    const cleanedPathname = pathname.substring(1);

    const findMatchingPermission = (permissions) => {
      for (const permission of permissions) {
        if (permission?.pageUrl === cleanedPathname) {
          setMatchingPermission(permission);
          return;
        }

        if (permission?.children && Array.isArray(permission.children)) {
          findMatchingPermission(permission.children);
        }
      }
    };

    if (Array.isArray(links.permissions)) {
      findMatchingPermission(links.permissions);
    }

    if (matchingPermission && matchingPermission.permissions) {
      const permissionObject = {};

      matchingPermission.permissions.forEach((permission) => {
        const actionName = permission.action;
        permissionObject[actionName] = permission;
      });

      setIsPermission(permissionObject);
    }
  }, [pathname, links.permissions, matchingPermission]);

  useEffect(() => {
    const savedLanguage = JSON.parse(localStorage.getItem("language"));
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage.code);
    }
  }, [i18n]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("selectedCountryId");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("parcelGroupId");
    };

    const parcelGroupPath = `/ParcelGroup/${parcelGroupId}`;
    const parcelPath = `/ParcelGroup/parcel/${parcelId}`;

    if (!pathname.startsWith("/ParcelGroup")) {
      localStorage.removeItem("selectedCountryId");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("parcelGroupId");
    }

    if (parcelGroupPath !== pathname && parcelPath !== pathname) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [parcelGroupId, parcelId, pathname]);

  useEffect(() => {
    setSelectedLanguage(getInitialLanguage());
  }, []);

  //functions
  const handleShowMainModal = () => {
    setShowMainModal(!showMainModal);
  };

  const handleMobileMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const changeLanguage = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang.code);
    localStorage.setItem("language", JSON.stringify(lang));
  };

  const getInitialLanguage = () => {
    const savedLanguage = JSON.parse(localStorage.getItem("language"));
    return savedLanguage || { code: "en", name: "United States - English" };
  };

  const contextValue = {
    handleMobileMenu,
    handleShowMainModal,
    setShowMainModal,
    setGroupsCountryId,
    setGroupsFormData,
    setGroupsIds,
    setGlobalPeriod,
    changeLanguage,
    groupsCountryId,
    showMainModal,
    isPermission,
    groupsFormData,
    groupsIds,
    menuOpen,
    globalPeriod,
    selectedLanguage,
    setParcelGroupId,
    setParcelId,
    shouldRefetch,
    setShouldRefetch,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
