// import { useState, useEffect } from "react";
// import { getResponseTimeDetails } from "../../../features/dashboard/responseTime/ResponseTimeSlice";
// import { useDispatch, useSelector } from "react-redux";
// // import { BackSvg } from "../../../components/icons";
// // import { Link } from "react-router-dom";
// // import DashboardLayout from "../../../components/layouts/DashboardLayout";
// // import SpinnerDashboard from "../../../components/common/SpinnerDashboard";
// // import AverageTimeDetailsElement from "../../../components/dashboard/components/common/AverageTimeDetailsElement";
// // import InnerPagesPeriods from "../../../components/dashboard/components/common/InnerPagesPeriods";
// // import InnerPagesBranches from "../../../components/dashboard/components/common/InnerPagesBranches";
// // import GroupsMessage from "../../../components/table/components/GroupsMessage";
// // import ResponseTimeChart from "../../../components/dashboard/components/responseTime/ResponseTimeChart";
// // import ResponseTimeChartSmall from "../../../components/dashboard/components/responseTime/ResponseTimeChartSmall";
// // import Pagination from "../../../components/dashboard/components/common/Pagination";

// const ResponseTimeDetails = () => {
//   const [formData, setFormData] = useState({
//     reportType: "calls",
//     groupPeriod: "",
//     departmentId: "",
//   });
//   const [longestTime, setLongestTime] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isUser, setIsUser] = useState(false);
//   const [userData, setUserData] = useState([]);
//   const [activeName, setActiveName] = useState(null);
//   const elementsPerPage = 12;
//   const { reportType, groupPeriod, departmentId } = formData;
//   const { isLoading, isError, responseTimeDetails } = useSelector(
//     (state) => state.responseTime
//   );
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getResponseTimeDetails({ reportType, groupPeriod, departmentId }));
//   }, [dispatch, reportType, groupPeriod, departmentId]);

//   const timeToSeconds = (timeStr) => {
//     const [hours, minutes, seconds] = timeStr.split(":").map(Number);
//     return hours * 3600 + minutes * 60 + seconds;
//   };

//   const secondsToTime = (totalSeconds) => {
//     const hours = Math.floor(totalSeconds / 3600);
//     const remainingSeconds = totalSeconds % 3600;
//     const minutes = Math.floor(remainingSeconds / 60);
//     const seconds = remainingSeconds % 60;
//     return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
//       2,
//       "0"
//     )}:${String(seconds).padStart(2, "0")}`;
//   };

//   useEffect(() => {
//     let longestTimeSeconds = 0;

//     responseTimeDetails?.details?.forEach((record) => {
//       const currentTimeSeconds = timeToSeconds(record.avgTime);
//       if (currentTimeSeconds > longestTimeSeconds) {
//         longestTimeSeconds = currentTimeSeconds;
//       }
//     });

//     const longestTime = secondsToTime(longestTimeSeconds);
//     setLongestTime(longestTime);
//   }, [responseTimeDetails]);

//   const indexOfLastElement = currentPage * elementsPerPage;
//   const indexOfFirstElement = indexOfLastElement - elementsPerPage;
//   const currentDetails = responseTimeDetails?.details?.slice(
//     indexOfFirstElement,
//     indexOfLastElement
//   );

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   const totalPages = Math.ceil(
//     responseTimeDetails?.details?.length / elementsPerPage
//   );

//   const handleGetWeekResponse = (name) => {
//     if (name === activeName) {
//       setIsUser(false);
//       setActiveName(null);
//       setUserData([]);
//     } else {
//       setIsUser(true);
//       setActiveName(name);
//       const filteredData = responseTimeDetails?.detailsByUser?.filter(
//         (item) => item.dstChannel === name
//       );
//       setUserData(filteredData);
//     }
//   };

//   return (
//     <DashboardLayout>
//       <div className="w-full bg-gray-100 p-3 flex items-center justify-between border-b border-gray-300 md:flex-col md:items-start md:gap-2">
//         <div className="flex items-center gap-3">
//           <Link
//             to="/MainPage"
//             className="bg-gray-100 p-3 rounded-md flex items-center justify-center h-11 w-11 hover:svg path { stroke: #416ff4 }"
//           >
//             <BackSvg />
//           </Link>
//           <div className="text-2xl font-semibold text-gray-800 md:text-lg">
//             Average response time
//           </div>
//         </div>
//         <div className="flex items-center gap-4 relative z-50 md:flex-col md:items-start md:gap-2 w-full">
//           <InnerPagesPeriods setFormData={setFormData} />
//           <InnerPagesBranches setFormData={setFormData} />
//         </div>
//       </div>
//       {isLoading ? (
//         <SpinnerDashboard />
//       ) : isError ? (
//         <GroupsMessage text="Something went wrong, please try again" />
//       ) : (
//         <div className="p-8 w-full flex flex-col items-center gap-24 md:p-6 md:gap-16">
//           <div className="w-full flex flex-col items-end gap-4">
//             {currentDetails?.length > 0 ? (
//               <div className="w-full grid grid-cols-4 gap-4 md:grid-cols-2 md:gap-4 sm:grid-cols-1">
//                 {currentDetails?.map((detail, index) => (
//                   <AverageTimeDetailsElement
//                     key={index}
//                     detail={detail}
//                     longestTime={longestTime}
//                     onClick={() => handleGetWeekResponse(detail.dstChannel)}
//                     isActive={detail.dstChannel === activeName}
//                   />
//                 ))}
//               </div>
//             ) : (
//               <GroupsMessage text="No List Result" />
//             )}

//             {currentDetails?.length > 0 && (
//               <Pagination
//                 totalPages={totalPages}
//                 onPageChange={handlePageChange}
//                 currentPage={currentPage}
//               />
//             )}
//           </div>
//           {isUser ? (
//             <ResponseTimeChartSmall data={userData} />
//           ) : (
//             // <ResponseTimeChart data={currentDetails} />
//             <>h</>
//           )}
//         </div>
//       )}
//     </DashboardLayout>
//   );
// };

// export default ResponseTimeDetails;

import React from "react";

const index = () => {
  return <div>index</div>;
};

export default index;
