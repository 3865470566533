import { Link } from "react-router-dom";
import { BackSvg } from "../../components/icons";
import { useTranslation } from "react-i18next";
import createNotification from "../../assets/create-notification.svg";
import NotificationsTable from "../../components/table/notifications/NotificationsTable";
import NotificationsLayout from "./NotificationsLayout";

const Notifications = () => {
  const { t } = useTranslation();
  return (
    <NotificationsLayout>
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to="/MainPage"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          <div className="text-xl font-semibold text-gray-800">
            {t("text.notifications")}
          </div>
        </div>
        <Link
          className="bg-blue-2/15 py-px px-8 rounded-lg flex justify-center items-center gap-1.5 whitespace-nowrap text-xs font-medium text-blue-2 cursor-pointer transition-all duration-300 hover:bg-blue-2/50"
          to="/notifications/create"
        >
          <img
            className="w-8 h-9 object-contain"
            src={createNotification}
            alt="add"
          />
          Create Notification
        </Link>
      </div>
      <NotificationsTable />
    </NotificationsLayout>
  );
};

export default Notifications;
