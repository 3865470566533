import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendEmail } from "../../../features/groups/GroupsSlice";
import ButtonDefault from "../../buttons/ButtonDefault";
import grammerlySVG from "../../../assets/grammerly.svg";
import Input from "../../common/Input";
import ModalsHeadline from "../components/ModalsHeadline";

const SendEmail = ({
  setShowSendEmail,
  groupsIds,
  setResponseMessage,
  setShowResponseMessage,
  closeModal,
  setGroupsIds,
  setSelectedRows,
}) => {
  const [formData, setFormData] = useState({
    parcelGroupIds: [],
    emailSubject: "",
    emailText: "",
  });
  const [errors, setErrors] = useState({
    emailText: "",
    emailSubject: "",
  });
  const [rows, setRows] = useState(1);
  const { parcelGroupIds, emailText, emailSubject } = formData;
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });

    const textareaLineHeight = 20;
    e.target.rows = 1;
    const currentRows = Math.ceil(e.target.scrollHeight / textareaLineHeight);
    e.target.rows = currentRows;
    setRows(currentRows);
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      parcelGroupIds: groupsIds,
    }));
  }, [groupsIds]);

  const handleSendEmail = async (e) => {
    e.preventDefault();

    if (!emailText || !emailSubject) {
      setErrors({
        emailText: emailText ? "" : "Email Text is required",
        emailSubject: emailSubject ? "" : "Subject Text is required",
      });
      return;
    }

    const emailData = { parcelGroupIds, emailText, emailSubject };

    try {
      const response = await dispatch(sendEmail(emailData));
      if (response.payload.type === "success") {
        setShowSendEmail(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        setGroupsIds([]);
        setSelectedRows([]);
        setFormData({
          emailText: "",
          emailSubject: "",
        });
      }
      if (response.error.message === "Rejected") {
        setShowSendEmail(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          emailText: error.response.data.emailText || "",
          emailSubject: error.response.data.emailSubject || "",
        });
      }
    }
  };

  return (
    <form
      className="flex flex-col justify-between rounded-lg h-full"
      onSubmit={handleSendEmail}
    >
      <div>
        <ModalsHeadline text="Send Email" onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text="Subject"
              name="emailSubject"
              value={formData.emailSubject}
              onchange={handleInputChange}
              error={errors.emailSubject}
              errormark={errors.emailSubject ? "error-border" : ""}
            />
            {errors.emailSubject && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.emailSubject}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <div
              className={`relative w-full border ${
                errors.emailText ? "border-red-500" : "border-gray2"
              } rounded-lg p-3 bg-no-repeat bg-right-bottom`}
              style={{
                backgroundImage: `url(${grammerlySVG})`,
                paddingBottom: "45px",
              }}
            >
              <textarea
                placeholder="Type Email here"
                name="emailText"
                value={formData.emailText}
                onChange={handleInputChange}
                rows={rows}
                className="w-full outline-none min-h-[220px] h-full text-base font-normal text-gray-6 resize-none border-none placeholder:text-gray-4 placeholder:font-normal"
              />
            </div>
            {errors.emailText && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.emailText}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">Send Email</ButtonDefault>
      </div>
    </form>
  );
};

export default SendEmail;
