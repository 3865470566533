import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerScoreService from "./CustomerScoreService";

const initialState = {
  score: [],
  scoreDetails: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getCustomerScore = createAsyncThunk(
  "customerScoreReport/get_report",
  async ({ reportType, groupPeriod }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await customerScoreService.getCustomerScore(
        token,
        reportType,
        groupPeriod
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const getCustomerScoreDetails = createAsyncThunk(
  "customerScoreReport/get_report_details",
  async (
    {
      reportType,
      groupPeriod,
      departmentId,
      page,
      perPage,
      userName,
      tdsCode,
      startDate,
      endDate,
      rate,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await customerScoreService.getCustomerScoreDetails(
        token,
        reportType,
        groupPeriod,
        departmentId,
        page,
        perPage,
        userName,
        tdsCode,
        startDate,
        endDate,
        rate
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const exportExcel = createAsyncThunk(
  "customerScoreReport/get_report_details_excel",
  async (
    {
      reportType,
      groupPeriod,
      departmentId,
      page,
      perPage,
      userName,
      tdsCode,
      startDate,
      endDate,
      rate,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await customerScoreService.exportExcel(
        token,
        reportType,
        groupPeriod,
        departmentId,
        page,
        perPage,
        userName,
        tdsCode,
        startDate,
        endDate,
        rate
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const customerScoreSlice = createSlice({
  name: "customerScore",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerScore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerScore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.score = action.payload;
        state.statusCode = 200;
      })
      .addCase(getCustomerScore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(getCustomerScoreDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerScoreDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.scoreDetails = action.payload;
        state.statusCode = 200;
      })
      .addCase(getCustomerScoreDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = customerScoreSlice.actions;
export default customerScoreSlice.reducer;
