import { useSelector } from "react-redux";
import { ExportFileSvg } from "../../icons";
import { Tooltip } from "@mui/material";
import Checkbox from "../../common/Checkbox";
import TableDropDown from "../../common/TableDropDown";
import Pagination from "../../../components/common/Pagination";
import Spinner from "../../common/Spinner";
import Message from "../../common/Message";
import CheckAll from "../../common/CheckAll";
import DatePickerComponent from "../../common/DatePickerComponent";
import TableSearch from "../../common/TableSearch";
import { useTranslation } from "react-i18next";

const SatisfactionScoreTable = ({
  data,
  handlePageClick,
  currentPage,
  onNextPage,
  onPrevPage,
  handlePerPage,
  perPage,
  formData,
  setFormData,
}) => {
  const { isLoading } = useSelector((state) => state.score);
  const recordsNumber = useSelector(
    (state) => state.score?.scoreDetails?.recordsNumber
  );
  const { t } = useTranslation();

  const columnkeymappings = {
    [t("text.user")]: "userName",
    [t("text.TDS_code")]: "TDS_CODE",
    [t("text.delivery_time")]: "changeDate",
    [t("text.satisfaction_score")]: "rate",
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20 no-scrollbar overflow-x-scroll">
          <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center">
                    <CheckAll />
                  </div>
                </th>
                {Object.keys(columnkeymappings).map((key) => (
                  <th
                    key={key}
                    className="max-w-5 text-left border-b font-medium min-w-26"
                  >
                    <div className="relative p-4 w-full border-r border-black/5">
                      {(key === `${t("text.user")}` ||
                        key === `${t("text.TDS_code")}` ||
                        key === `${t("text.satisfaction_score")}`) && (
                        <>
                          <span className="block truncate pr-2"> {key}</span>
                          <TableSearch
                            formData={formData}
                            setFormData={setFormData}
                            searchKey={
                              key === `${t("text.TDS_code")}`
                                ? "tdsCode"
                                : columnkeymappings[key]
                            }
                          />
                        </>
                      )}
                      {key === `${t("text.delivery_time")}` && (
                        <div className="relative flex justify-between items-center gap-2">
                          <span className="truncate">{key}</span>
                          <DatePickerComponent
                            value={{
                              startDate: formData.startDate,
                              endDate: formData.endDate,
                            }}
                            onChange={(newValue) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                startDate: newValue.startDate,
                                endDate: newValue.endDate,
                              }));
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20 min-w-20">
                  <div className="p-4 w-full truncate">
                    {t("text.quantity")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data.map((row, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-black/5 border-b"
                  >
                    <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                      <Checkbox />
                    </td>
                    {Object.keys(columnkeymappings).map((key) => {
                      const dataKey = columnkeymappings[key];
                      return (
                        <td
                          key={key}
                          className="max-w-5 text-xs text-blue-4 font-medium"
                        >
                          <div
                            className={`${
                              key !== `${t("text.TDS_code")}` && "truncate"
                            } py-2 px-3 w-full border-r border-black/5`}
                          >
                            {key === `${t("text.TDS_code")}` ? (
                              <TableDropDown data={row} />
                            ) : (
                              <Tooltip title={row[dataKey]}>
                                <span>{row[dataKey]}</span>
                              </Tooltip>
                            )}
                          </div>
                        </td>
                      );
                    })}
                    <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                      <Tooltip title={t("text.export")}>
                        <span className="cursor-pointer">
                          <ExportFileSvg />
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={Object.keys(columnkeymappings).length + 2}
                    className="border-none p-2"
                  >
                    <Message text={t("text.not_found")} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {recordsNumber > 1 && (
            <Pagination
              currentPage={currentPage}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              handlePerPage={handlePerPage}
              perPage={perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SatisfactionScoreTable;
