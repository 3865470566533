import { useEffect, useState } from "react";
import { HidePasswordSvg, ShowPasswordSvg } from "../../components/icons";
import { useDispatch, useSelector } from "react-redux";
import { login, reset } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import Input from "../../components/common/Input";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    channel: "ADMIN",
  });
  const [errors, setErrors] = useState({
    userName: "",
    password: "",
  });

  const { userName, password, channel } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      setShowResponseMessage(true);
      setResponseMessage(message);
    }
    if (isSuccess || user) {
      navigate("/MainPage");
    }
    dispatch(reset());
  }, [user, isError, isSuccess, message, dispatch, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setShowResponseMessage(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!userName || !password) {
      setErrors({
        userName: userName ? "" : "Username is required",
        password: password ? "" : "Password is required",
      });
      return;
    }

    const userData = {
      userName,
      password,
      channel,
    };

    try {
      dispatch(login(userData));
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          userName: error.response.data.userName || "",
          password: error.response.data.password || "",
        });
      }
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center justify-center h-screen w-screen p-6 ">
      <div className="max-w-[520px] w-full flex flex-col items-center">
        <h1 className="font-bold mb-4 text-center text-blue-1 text-2xl md:text-4xl">
          Hello again
        </h1>
        <p className="text-sm font-normal mb-16 text-center">
          In order to use the control panel, you must be an employee of Space
          cargo
        </p>
        <form
          onSubmit={handleLogin}
          className="flex flex-col items-center gap-6 w-full"
        >
          <div className="relative w-full">
            <Input
              text="Username, TBS or Email"
              name="userName"
              value={formData.userName}
              onchange={handleInputChange}
              error={errors.userName}
              errormark={errors.userName ? "error-border" : ""}
            />
            {errors.userName && (
              <div className="text-red-500 bg-white text-xs font-normal absolute -bottom-2 left-4">
                {errors.userName}
              </div>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text="Password"
              type={showPassword ? "text" : "password"}
              isicon="isicon"
              handleshowpassword={handleShowPassword}
              icon={showPassword ? <ShowPasswordSvg /> : <HidePasswordSvg />}
              name="password"
              value={formData.password}
              onchange={handleInputChange}
              error={errors.password}
              errormark={errors.password ? "error-border" : ""}
            />
            {errors.password && (
              <div className="text-red-500 bg-white text-xs font-normal absolute -bottom-2 left-4">
                {errors.password}
              </div>
            )}
          </div>

          <div className="max-w-96 w-full mt-36">
            <ButtonPrimary type="submit">Log in</ButtonPrimary>
          </div>
          {showResponseMessage && (
            <div className="text-red-500 text-xs font-normal mt-4">
              {responseMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default SignIn;
