import axios from "axios";

const getResponseTime = async (
  token,
  reportType,
  groupPeriod,
  departmentId
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report`,
    { ...config, params: data }
  );

  return response.data;
};

const getResponseTimeDetails = async (
  token,
  reportType,
  groupPeriod,
  departmentId
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report_details`,
    { ...config, params: data }
  );

  return response.data;
};

const responseTimeService = {
  getResponseTime,
  getResponseTimeDetails,
};

export default responseTimeService;
