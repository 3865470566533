import ModalsHeadline from "../components/ModalsHeadline";
import { DeleteSvg, DocumentSvg } from "../../icons";
import UploadFileOrTakeAPicture from "../../common/UploadFileOrTakeAPicture";
import { useTranslation } from "react-i18next";

const EditParcelPictures = ({ handleShowModal, formData, setFormData }) => {
  const { t } = useTranslation();
  const handleFileChange = (e) => {
    const newFiles = e.target.files;

    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": [...prevFormData["file[]"], ...Array.from(newFiles)],
    }));
  };

  const handleDeleteFile = (index) => {
    const newFiles = [...formData["file[]"]];
    newFiles.splice(index, 1);

    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": newFiles,
    }));
  };

  const handleOpenFile = (file) => {
    const fileUrl = file.fileUrl || URL.createObjectURL(file);
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="flex flex-col justify-between rounded-lg h-full">
      <div>
        <ModalsHeadline text="Update photos" onClick={handleShowModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <UploadFileOrTakeAPicture onChange={handleFileChange} />
          {formData["file[]"].length > 0 ? (
            formData["file[]"]?.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 bg-gray-100 rounded-lg shadow cursor-pointer"
              >
                <div className="flex items-center gap-3 w-[calc(100%-40px)]">
                  <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
                    <DocumentSvg />
                  </div>
                  <h3
                    onClick={() => handleOpenFile(file)}
                    className="text-sm font-medium text-blue-900 truncate w-[calc(100%-40px)]"
                  >
                    {file.fileName || file.name}
                  </h3>
                </div>
                <button
                  type="button"
                  onClick={() => handleDeleteFile(index)}
                  className="p-2 text-red-500 hover:text-red-700 transition-colors duration-300"
                >
                  <DeleteSvg />
                </button>
              </div>
            ))
          ) : (
            <div className="w-full pt-10 flex justify-center items-center text-sm text-blue-1">
              {t("text.not_found")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditParcelPictures;
