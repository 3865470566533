import { useState, useEffect, useRef } from "react";
import { ArrowDownSvg } from "../icons";

const TableDropDown = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropRef.current && !dropRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative h-full" ref={dropRef}>
      <div
        onClick={handleOpen}
        className="text-xs flex items-center justify-between w-full h-full cursor-pointer text-blue-4 font-medium"
      >
        <span className="overflow-hidden text-ellipsis whitespace-nowrap">
          {data?.parcels[0].TDS_CODE}
        </span>
        <div className="min-w-4 w-4">
          <ArrowDownSvg />
        </div>
      </div>
      {isOpen && (
        <div className="absolute z-20 max-h-20 overflow-scroll top-full mt-2 w-max bg-white-1 border border-purple-2 rounded-md shadow-lg">
          {data?.parcels?.map((parcel, index) => (
            <div
              key={index}
              as="div"
              className="py-1 px-2 overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {parcel.TDS_CODE}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableDropDown;
