const Input = ({ text, onChange, type }) => {
  return (
    <label className="flex flex-col items-start max-w-[382px] w-full gap-2 sm:max-w-full">
      <span className="text-base font-normal text-gray-1">{text}</span>
      <input
        type={type}
        placeholder={type !== "password" ? text : ""}
        onChange={onChange}
        className="w-full px-4 py-4 bg-white-1 border border-black/5 rounded-lg text-base font-normal text-gray-1"
      />
    </label>
  );
};

export default Input;
