import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExportFileSvg } from "../../icons";
import Checkbox from "../../common/Checkbox";
import Pagination from "../../common/Pagination";
import Spinner from "../../common/Spinner";
import Message from "../../common/Message";
import CheckAll from "../../common/CheckAll";
import TableSearch from "../../common/TableSearch";
import DatePickerComponent from "../../common/DatePickerComponent";
import TableSearchSelect from "../../common/TableSearchSelect";

const IssuedParcelsTable = ({
  data,
  handlePageClick,
  currentPage,
  onNextPage,
  onPrevPage,
  handlePerPage,
  perPage,
  formData,
  setFormData,
}) => {
  const [selectedStatusName, setSelectedStatusName] = useState("");
  const { isLoading } = useSelector((state) => state.issuedParcels);
  const recordsNumber = useSelector(
    (state) => state.issuedParcels?.terminalRequestsDetails?.recordsNumber
  );
  const { t } = useTranslation();

  const columnkeymappings = {
    [t("text.user")]: "userName",
    [t("text.TDS_code")]: "tdsCode",
    [t("text.withdraw_time")]: "withdrawDate",
    [t("text.rate")]: "rate",
    [t("text.request_time")]: "inpDate",
    [t("text.status")]: "status",
  };

  const statuses = useMemo(
    () => [
      { name: [t("text.success")], value: "C" },
      { name: [t("text.error")], value: "E" },
    ],
    [t]
  );

  useEffect(() => {
    const status = statuses.find((status) => status.value === formData?.status);
    setSelectedStatusName(status?.name);
  }, [formData, statuses]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20 no-scrollbar overflow-x-scroll">
          <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center">
                    <CheckAll />
                  </div>
                </th>
                {Object.keys(columnkeymappings).map((key) => (
                  <th
                    key={key}
                    className="max-w-5 text-left border-b font-medium min-w-26"
                  >
                    <div className="relative p-4 w-full border-r border-black/5">
                      {(key === `${t("text.user")}` ||
                        key === `${t("text.TDS_code")}` ||
                        key === `${t("text.rate")}`) && (
                        <>
                          <span className="block truncate pr-2"> {key}</span>
                          <TableSearch
                            formData={formData}
                            setFormData={setFormData}
                            searchKey={columnkeymappings[key]}
                          />
                        </>
                      )}
                      {key === `${t("text.withdraw_time")}` && (
                        <div className="relative flex justify-between items-center gap-2">
                          <span className="truncate">{key}</span>
                          <DatePickerComponent
                            value={{
                              startDate: formData.withdrawStartDate,
                              endDate: formData.withdrawEndDate,
                            }}
                            onChange={(newValue) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                withdrawStartDate: newValue.startDate,
                                withdrawEndDate: newValue.endDate,
                              }));
                            }}
                          />
                        </div>
                      )}
                      {key === `${t("text.request_time")}` && (
                        <div className="relative flex justify-between items-center gap-2">
                          <span className="truncate">{key}</span>
                          <DatePickerComponent
                            value={{
                              startDate: formData.inpStartDate,
                              endDate: formData.inpEndDate,
                            }}
                            onChange={(newValue) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                inpStartDate: newValue.startDate,
                                inpEndDate: newValue.endDate,
                              }));
                            }}
                          />
                        </div>
                      )}
                      {key === `${t("text.status")}` && (
                        <div className="relative flex justify-between items-center gap-2">
                          <span className="truncate">{key}</span>
                          <TableSearchSelect
                            setFormData={setFormData}
                            formData={formData}
                            data={statuses}
                            searchKey={columnkeymappings[key]}
                            selectedValue={selectedStatusName}
                            translationKey="name"
                            valueKey="value"
                          />
                        </div>
                      )}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20  min-w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data.map((row, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-black/5 border-b"
                  >
                    <td className="py-2 px-3 border-r border-black/5">
                      <Checkbox type="select" />
                    </td>
                    {Object.keys(columnkeymappings).map((key) => {
                      const dataKey = columnkeymappings[key];
                      let cellContent = row[dataKey];
                      let statusClass = "";
                      if (key === `${t("text.status")}`) {
                        statusClass =
                          cellContent === "C"
                            ? "bg-blue-6/30 text-blue-6"
                            : "bg-red-500/30 text-red-500";
                        cellContent =
                          cellContent === "C"
                            ? `${t("text.success")}`
                            : `${t("text.error")}`;
                      }
                      return (
                        <td
                          key={key}
                          className="max-w-5 text-xs text-blue-4 font-medium"
                        >
                          <div className="py-2 px-3 w-full border-r border-black/5">
                            <Tooltip title={cellContent}>
                              <span
                                className={`flex justify-center items-center rounded w-full text-xs text-blue-4 font-medium ${
                                  key === `${t("text.status")}`
                                    ? statusClass
                                    : ""
                                }`}
                              >
                                <span className="truncate">{cellContent}</span>
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                      );
                    })}
                    <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                      <div className="flex gap-2">
                        <Tooltip title={t("text.export")}>
                          <button>
                            <ExportFileSvg />
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={Object.keys(columnkeymappings).length + 2}
                    className="border-none p-2"
                  >
                    <Message text={t("text.not_found")} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {recordsNumber > 1 && (
            <Pagination
              currentPage={currentPage}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              handlePerPage={handlePerPage}
              perPage={perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default IssuedParcelsTable;
