import { useState } from "react";
import { useDispatch } from "react-redux";
import { Snackbar } from "@mui/material";
import { useAppContext } from "../../libs/AppContext";
import { useTranslation } from "react-i18next";
import {
  deleteFileCategory,
  getFileCategories,
} from "../../features/filecategories/FileCategoriesSlice";
import TableHeadline from "../common/TableHeadline";
import FileCategoriesTable from "../table/filecategories/FileCategoriesTable";
import Modal from "../common/Modal";
import EditFileCategory from "../../components/modals/filecategories/EditFileCategory";
import AddFileCategory from "../../components/modals/filecategories/AddFileCategory";
import DeleteConfirmation from "../common/DeleteConfirmation";
import AddButton from "../common/AddButton";

const FileCategoriesLayout = ({ data }) => {
  const [showEditFileCategories, setShowEditFileCategories] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [fileId, setFileId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const { setShowMainModal, showMainModal, handleShowMainModal } =
    useAppContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleShowEditFile = (id) => {
    setShowEditFileCategories(!showEditFileCategories);
    setFileId(id);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setFileId(id);
  };

  const handleDeleteFileCategory = async () => {
    const fileData = {
      categoryId: fileId,
    };

    const response = await dispatch(deleteFileCategory(fileData));
    if (response.payload.type === "success") {
      dispatch(getFileCategories());
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setResponseMessage(response.payload.message);
    }

    if (response.error.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setResponseMessage(response.payload.message);
    }
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  return (
    <div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          message={responseMessage}
        />
      )}
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <AddFileCategory
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          setShowModal={setShowMainModal}
          closeModal={handleShowMainModal}
        />
      </Modal>
      <Modal
        showmodal={showEditFileCategories}
        setShowModal={setShowEditFileCategories}
      >
        <EditFileCategory
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          handleShowEditFile={handleShowEditFile}
          setShowEditFileCategories={setShowEditFileCategories}
          fileId={fileId}
        />
      </Modal>
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteFileCategory}
          text={t("text.file_category")}
        />
      )}

      <TableHeadline text={t("text.delegationFileCategory")} />
      <FileCategoriesTable
        handleShowEditFile={handleShowEditFile}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />
      <AddButton />
    </div>
  );
};

export default FileCategoriesLayout;
