import axios from "axios";

const getClients = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/get_users`,
    config
  );

  return response.data;
};

const deleteClient = async (clientId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delete_user`,
    clientId,
    config
  );

  return response.data;
};

const registerClient = async (clientData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/register_user`,
    clientData,
    config
  );

  return response.data;
};

const updateClient = async (updatedClientData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/change_user`,
    updatedClientData,
    config
  );

  return response.data;
};

const removeAttachments = async (attachmentIds, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/remove_user_attachment`,
    attachmentIds,
    config
  );

  return response.data;
};

const clientsService = {
  getClients,
  deleteClient,
  registerClient,
  updateClient,
  removeAttachments,
};

export default clientsService;
