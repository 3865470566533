const Modal = ({ showmodal, children, setShowModal }) => {
  const handleWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 h-screen w-screen bg-black/10 backdrop-blur-sm z-50 flex justify-end items-stretch transition-all duration-500 ${
        showmodal ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
      onClick={handleWrapperClick}
    >
      <div
        className={`max-w-xxl w-full bg-white h-screen overflow-y-auto transition-transform duration-500 ${
          showmodal ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
