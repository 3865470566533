import GroupsLayout from "../../components/layouts/GroupsLayout";
import { useDispatch } from "react-redux";
import { useAppContext } from "../../libs/AppContext";
import { useEffect } from "react";
import {
  getCountries,
  getDepartments,
  getGroups,
} from "../../features/groups/GroupsSlice";

const GroupsList = () => {
  const dispatch = useDispatch();
  const storedId = localStorage.getItem("selectedCountryId");
  const { searchByNumber, searchByFlights, groupsFormData, setGroupsFormData } =
    useAppContext();
  const { warehouseCountryId, flightNumber, status, barcode, page, perPage } =
    groupsFormData;

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getDepartments());
  }, [dispatch]);

  useEffect(() => {
    let countryId = parseInt(storedId);
    setGroupsFormData((prev) => ({
      ...prev,
      warehouseCountryId: countryId,
    }));
  }, [setGroupsFormData, storedId]);

  useEffect(() => {
    setGroupsFormData((prevData) => ({
      ...prevData,
      flightNumber: searchByFlights,
      barcode: searchByNumber,
      page: 1,
    }));
  }, [searchByNumber, searchByFlights, setGroupsFormData]);

  useEffect(() => {
    if (warehouseCountryId) {
      dispatch(
        getGroups({
          warehouseCountryId,
          flightNumber,
          status,
          barcode,
          page,
          perPage,
        })
      );
    }
  }, [
    dispatch,
    warehouseCountryId,
    flightNumber,
    status,
    barcode,
    page,
    perPage,
  ]);

  useEffect(() => {
    localStorage.removeItem("parcelsPage");
  }, []);

  useEffect(() => {
    if (storedId) {
      localStorage.setItem("currentPage", page);
    }
  }, [page, storedId]);

  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");
    if (storedPage) {
      let page = parseInt(storedPage);
      setGroupsFormData((prevData) => ({
        ...prevData,
        page: page,
      }));
    }
  }, [setGroupsFormData]);

  const handlePageIncrement = () => {
    setGroupsFormData((prevData) => ({ ...prevData, page: page + 1 }));
  };

  const handlePageDecrement = () => {
    if (page > 1) {
      setGroupsFormData((prevData) => ({ ...prevData, page: page - 1 }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setGroupsFormData((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setGroupsFormData((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <GroupsLayout
      onNextPage={handlePageIncrement}
      onPrevPage={handlePageDecrement}
      handlePageClick={handlePageClick}
      handlePerPage={handlePerPage}
      currentPage={page}
      perPage={perPage}
    />
  );
};

export default GroupsList;
