import axios from "axios";

const login = async (userData) => {
  const response = await axios.post(
    `${process.env.REACT_APP_URI}/login`,
    userData
  );

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  login,
  logout,
};

export default authService;
