import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";
import Input from "../../common/Input";
import {
  getDepartments,
  updateDepartment,
} from "../../../features/departments/DepartamentsSlice";
import { useTranslation } from "react-i18next";

const EditDepartment = ({
  setResponseMessage,
  setShowResponseMessage,
  handleShowEditEmployee,
  departmentID,
  setShowEditEmployee,
}) => {
  const [prevData, setPrevData] = useState({});
  const [formData, setFormData] = useState({
    departmentId: "",
    departmentName: "",
  });
  const [errors, setErrors] = useState({
    departmentName: "",
  });
  const { departmentName, departmentId } = formData;
  const { departments } = useSelector((state) => state.departments);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const filteredDepartment = departments?.find(
      (department) => department.id === departmentID
    );

    setPrevData(filteredDepartment);
  }, [departments, departmentID]);

  useEffect(() => {
    setFormData({
      departmentId: departmentID,
      departmentName: prevData?.name || "",
    });
  }, [prevData, departments, departmentID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditDepartment = async (e) => {
    e.preventDefault();

    if (!departmentName) {
      setErrors({
        departmentName: departmentName
          ? ""
          : `${t("text.department_name_required")}`,
      });
      return;
    }

    const updatedDepartmentData = {
      departmentId,
      departmentName,
    };

    try {
      const response = await dispatch(updateDepartment(updatedDepartmentData));
      if (response.payload.type === "success") {
        dispatch(getDepartments());
        setShowEditEmployee(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }

      if (response.error.message === "Rejected") {
        setShowEditEmployee(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          departmentName: error.response.data.departmentName || "",
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleEditDepartment}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.edit_department")}
          onClick={handleShowEditEmployee}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.name")}
              name="departmentName"
              value={formData.departmentName}
              onchange={handleInputChange}
              error={errors.departmentName}
              errormark={errors.departmentName ? "error-border" : ""}
            />
            {errors.departmentName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.departmentName}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.update")}</ButtonDefault>
      </div>
    </form>
  );
};

export default EditDepartment;
