import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../libs/AppContext";
import { BackSvg } from "../../components/icons";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  getCategoriesWithMaster,
  getCountries,
  getDepartments,
  getGroups,
  getParcels,
  getParcelServices,
} from "../../features/groups/GroupsSlice";
import ParcelsLayout from "../../components/layouts/ParcelsLayout";
import addParcelImage from "../../assets/add-parcel.png";

const GroupParcels = () => {
  const [formData, setFormData] = useState({
    parcelGroupId: "",
    tdsCode: "",
    roomNumber: "",
    parcelsPage: 1,
    parcelsPerPage: 10,
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [formHeight, setFormHeight] = useState(0);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [editParcelId, setEditParcelId] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const addParcelFormRef = useRef(null);
  const editParcelFormRef = useRef(null);
  const storedId = localStorage.getItem("selectedCountryId");
  const { groupsFormData, setGroupsFormData, setParcelGroupId } =
    useAppContext();
  const { warehouseCountryId, flightNumber, status, barcode, page, perPage } =
    groupsFormData;
  const { parcelGroupId, tdsCode, roomNumber, parcelsPage, parcelsPerPage } =
    formData;
  const groups = useSelector((state) => state.groups?.groups?.parcelGroups);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getDepartments());
    dispatch(getParcelServices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCategoriesWithMaster());
  }, [dispatch]);

  useEffect(() => {
    const storedPage = localStorage.getItem("parcelsPage");
    if (storedPage) {
      let page = parseInt(storedPage);
      setFormData((prev) => ({
        ...prev,
        parcelsPage: page,
      }));
    }
  }, []);

  useEffect(() => {
    let countryId = parseInt(storedId);
    setGroupsFormData((prev) => ({
      ...prev,
      warehouseCountryId: countryId,
    }));
  }, [setGroupsFormData, storedId]);

  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");
    if (storedPage) {
      let page = parseInt(storedPage);
      setGroupsFormData((prevData) => ({
        ...prevData,
        page: page,
      }));
    }
  }, [setGroupsFormData]);

  useEffect(() => {
    if (warehouseCountryId) {
      dispatch(
        getGroups({
          warehouseCountryId,
          flightNumber,
          status,
          barcode,
          page,
          perPage,
        })
      );
    }
  }, [
    dispatch,
    warehouseCountryId,
    flightNumber,
    status,
    barcode,
    page,
    perPage,
  ]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      parcelGroupId: id,
    }));
    setParcelGroupId(id);
  }, [id, setParcelGroupId]);

  useEffect(() => {
    localStorage.setItem("parcelsPage", parcelsPage);
  }, [parcelsPage]);

  useEffect(() => {
    if (parcelGroupId) {
      dispatch(
        getParcels({
          parcelGroupId,
          tdsCode,
          roomNumber,
          page: parcelsPage,
          perPage: parcelsPerPage,
        })
      );
    }
  }, [
    parcelGroupId,
    tdsCode,
    roomNumber,
    parcelsPage,
    parcelsPerPage,
    dispatch,
    shouldRefetch,
  ]);

  const foundGroup = groups?.find(
    (group) => parseInt(group.parcelGroupId) === parseInt(id)
  );

  const handlePageIncrement = () => {
    setFormData((prevData) => ({ ...prevData, parcelsPage: parcelsPage + 1 }));
  };

  const handlePageDecrement = () => {
    if (parcelsPage > 1) {
      setFormData((prevData) => ({
        ...prevData,
        parcelsPage: parcelsPage - 1,
      }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setFormData((prevData) => ({ ...prevData, parcelsPage: pageNumber }));
  };

  const handlePerPage = (e) => {
    setFormData((prevData) => ({ ...prevData, parcelsPerPage: e }));
  };

  const handleShowAddForm = () => {
    setShowEditForm(false);
    setEditParcelId("");
    if (addParcelFormRef.current) {
      const height = addParcelFormRef.current.scrollHeight;
      setFormHeight(showAddForm ? 0 : height);
    }
    setShowAddForm(!showAddForm);
  };

  const handleShowEditForm = (id) => {
    setShowAddForm(false);
    if (editParcelId === id) {
      setShowEditForm(!showEditForm);
    } else {
      setEditParcelId(id);
      setShowEditForm(true);
    }

    if (editParcelFormRef.current) {
      const height = editParcelFormRef.current.scrollHeight;
      setFormHeight(showEditForm ? 0 : height);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to="/ParcelGroup"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          {foundGroup && (
            <div className="text-2xl font-semibold text-gray-800">
              {t("text._navigation.parcelGroups")} -{" "}
              <span className="font-normal">{foundGroup?.barcode}</span>
            </div>
          )}
        </div>
        <button
          onClick={handleShowAddForm}
          className="bg-blue-2/15 py-px px-8 rounded-lg max-w-40 w-full flex justify-center items-center gap-1.5 whitespace-nowrap text-xs font-medium text-blue-2 cursor-pointer transition-all duration-300 hover:bg-blue-2/50"
        >
          <img
            className="w-8 h-9 object-contain"
            src={addParcelImage}
            alt="add"
          />
          Add Parcel
        </button>
      </div>
      {foundGroup && (
        <ParcelsLayout
          showAddForm={showAddForm}
          showEditForm={showEditForm}
          addParcelFormRef={addParcelFormRef}
          editParcelFormRef={editParcelFormRef}
          formHeight={formHeight}
          currentPage={parcelsPage}
          perPage={parcelsPerPage}
          editParcelId={editParcelId}
          onNextPage={handlePageIncrement}
          onPrevPage={handlePageDecrement}
          handlePageClick={handlePageClick}
          handlePerPage={handlePerPage}
          setShouldRefetch={setShouldRefetch}
          handleShowEditForm={handleShowEditForm}
          parcelsFormData={formData}
          setParcelsFormData={setFormData}
        />
      )}
    </div>
  );
};

export default GroupParcels;
