import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeRate, getGroups } from "../../../features/groups/GroupsSlice";
import { useAppContext } from "../../../libs/AppContext";
import { useTranslation } from "react-i18next";
import Input from "../../common/Input";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";

const ChangeRate = ({
  setShowChangeRate,
  groupsIds,
  setGroupsIds,
  setSelectedRows,
  setShowResponseMessage,
  setResponseMessage,
  closeModal,
}) => {
  const [formData, setFormData] = useState({
    parcelGroupIds: [],
    ccyRate: "",
  });

  const [errors, setErrors] = useState({
    parcelGroupIds: [],
    ccyRate: "",
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { groupsFormData } = useAppContext();
  const { parcelGroupIds, ccyRate } = formData;

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      parcelGroupIds: groupsIds,
    }));
  }, [groupsIds]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRateChange = async (e) => {
    e.preventDefault();

    if (!ccyRate) {
      setErrors({
        ccyRate: ccyRate ? "" : "New rate required",
      });
      return;
    }

    const newRateData = { parcelGroupIds, ccyRate };

    try {
      const response = await dispatch(changeRate(newRateData));

      if (response.payload.type === "success") {
        dispatch(getGroups(groupsFormData));
        setShowChangeRate(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        setGroupsIds([]);
        setSelectedRows([]);
        setFormData({
          ccyRate: "",
        });
      }

      if (response.error.message === "Rejected") {
        setShowChangeRate(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          ccyRate: error.response.data.ccyRate || "",
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleRateChange}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline text="Rate Change" onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text="Rate"
              name="ccyRate"
              value={formData.ccyRate}
              onchange={handleInputChange}
              error={errors.ccyRate}
              errormark={errors.ccyRate ? "error-border" : ""}
            />
            {errors.ccyRate && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.ccyRate}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">
          {t("text.text.chnage_rate")}
        </ButtonDefault>
      </div>
    </form>
  );
};

export default ChangeRate;
