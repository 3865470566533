import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trackingCodeService from "./trackingCodeService";

const initialState = {
  trackingCode: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//generate tracking code
export const generateTrackingCode = createAsyncThunk(
  "parcel/generate_tracking_code",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await trackingCodeService.genetareTrackingCode(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const trackingCodeSlice = createSlice({
  name: "tracking code",
  initialState,
  reducers: {
    rest: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateTrackingCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateTrackingCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.trackingCode = action.payload;
      })
      .addCase(generateTrackingCode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "change failed";
      });
  },
});

export const { reset } = trackingCodeSlice.actions;
export default trackingCodeSlice.reducer;
