import { CommingSoonSvg } from "../../components/icons";

const Projects = () => {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center">
      <CommingSoonSvg />
      <h3 className="text-[#475b78] text-xs font-medium mt-2">Coming soon</h3>
    </div>
  );
};

export default Projects;
