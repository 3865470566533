import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rolesService from "./RolesService";

const initialState = {
  roles: {},
  statusCode: null,
  rolesError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getRoles = createAsyncThunk(
  "projects/delegation/get_roles",
  async (departmentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await rolesService.getRoles(token, departmentId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.roles = action.payload;
        state.statusCode = 200;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.rolesError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = rolesSlice.actions;
export default rolesSlice.reducer;
