import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getShelfParcels } from "../../features/dashboard/shelfParcels/ShelfParcelsSlice";
import { useTranslation } from "react-i18next";
import { ArrowRightSvg } from "../icons";
import Spinner from "../common/Spinner";

const getColor = (index, isBackground) => {
  const colors = [
    { bg: "bg-pink-200", text: "text-pink-600" },
    { bg: "bg-indigo-200", text: "text-indigo-600" },
    { bg: "bg-teal-200", text: "text-teal-600" },
  ];
  const colorIndex = index % 3;
  return isBackground ? colors[colorIndex].bg : colors[colorIndex].text;
};

const ShelfParcels = () => {
  const [totalCount, setTotalCount] = useState(0);
  const { isLoading, shelfParcels } = useSelector(
    (state) => state.shelfParcels
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getShelfParcels({ reportType: "shelfParcels" }));
  }, [dispatch]);

  useEffect(() => {
    const countSum = shelfParcels?.reduce((sum, parcel) => {
      if (parcel.hasOwnProperty("count")) {
        sum += parcel.count;
      }
      return sum;
    }, 0);

    setTotalCount(countSum);
  }, [shelfParcels]);

  return (
    <div className="relative bg-white border border-gray-200 p-4 rounded-lg flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-base text-purple-5 font-medium">
          {totalCount} {t("text.parcels_on_the_shelf")}
        </h2>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-2">
          {shelfParcels?.map((parcel, index) => (
            <Link
              to="/MainPage"
              key={index}
              className="bg-pink-2/30 border border-black/5 rounded-lg p-4 flex justify-between items-center transition duration-500 ease-in-out hover:bg-pink-2"
            >
              <div className="flex items-center gap-4">
                <div
                  className={`h-12 w-12 flex items-center justify-center rounded-full ${getColor(
                    index,
                    true
                  )} ${getColor(index, false)} text-lg font-medium`}
                >
                  {parcel.departmentName.slice(0, 2).toUpperCase()}
                </div>
                <div className="flex flex-col items-start gap-1 text-sm font-medium text-purple-5">
                  <span>
                    {parcel.count} {t("text.parcels")}
                  </span>
                  <span>{parcel.departmentName}</span>
                </div>
              </div>
              <ArrowRightSvg fill="#8690C9" />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShelfParcels;
