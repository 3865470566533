import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getFileCategories,
  reset,
} from "../../features/filecategories/FileCategoriesSlice";
import { logout } from "../../features/auth/authSlice";
import FileCategoriesLayout from "../../components/layouts/FileCategoriesLayout";

const FileCategories = () => {
  const { isError } = useSelector((state) => state.fileCategories);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      dispatch(logout());
    }

    if (user) {
      dispatch(getFileCategories());
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, user, isError]);

  return <FileCategoriesLayout />;
};

export default FileCategories;
