import React from "react";
import Checkbox from "../../common/Checkbox";
import CheckAll from "../../common/CheckAll";
import { useTranslation } from "react-i18next";
import Spinner from "../../common/Spinner";
import { DeleteSvg2, EditSvg3, EyeSvg } from "../../icons";
import { Link } from "react-router-dom";

const NotificationsTable = () => {
  let isLoading = false;

  const { t } = useTranslation();

  //   const columns = [
  //     { header: t("text.name"), key: "name" },
  //     { header: t("text.bonusType"), key: "bonusType" },
  //     { header: t("text.bonusValue"), key: "bonusValue" },
  //   ];

  const columns = [
    {
      header: "Title",
      key: "title",
    },
    {
      header: "Group/User",
      key: "group/user",
    },
    {
      header: "Status",
      key: "status",
    },
    {
      header: "Type",
      key: "type",
    },
  ];

  const data = [
    {
      title: "title",
      "group/user": "group/user",
      status: "Status",
      type: "type",
    },
    {
      title: "title 2",
      "group/user": "group/user 2",
      status: "Status 2",
      type: "type 2",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20">
          <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center">
                    <CheckAll />
                  </div>
                </th>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className="max-w-5 text-left border-b font-medium"
                  >
                    <div className="p-4 w-full truncate border-r border-black/5">
                      {column.header}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 border-black/5 border-b"
                >
                  <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                    <Checkbox />
                  </td>
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className="max-w-5 text-xs text-blue-4 font-medium"
                    >
                      <div className="py-2 px-3 w-full truncate border-r border-black/5">
                        {row[column.key]}
                      </div>
                    </td>
                  ))}
                  <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                    <div className="flex gap-2">
                      <Link
                        className="w-4 flex justify-center items-center"
                        to="/notifications/details"
                      >
                        <EyeSvg />
                      </Link>
                      <Link
                        className="w-4 flex justify-center items-center"
                        to="/notifications/edit/123"
                      >
                        <EditSvg3 />
                      </Link>
                      <button className="w-4 flex justify-center items-center">
                        <DeleteSvg2 />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default NotificationsTable;
