import axios from "axios";

const getProducts = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_products`,
    config
  );

  return response.data;
};

const productsService = {
  getProducts,
};

export default productsService;
