import ClientsLayout from "../../components/layouts/ClientsLayout";
import { useDispatch, useSelector } from "react-redux";
import { reset, getClients } from "../../features/clients/ClientsSlice";
import { useCallback, useEffect, useState } from "react";
import { getCountries } from "../../features/countries/CountriesSlice";

const Clients = () => {
  const [clientsData, setClientsData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { clients, isError } = useSelector((state) => state.clients);
  const { countries, countriesError } = useSelector((state) => state.countries);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getClients());
      dispatch(getCountries());
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, user, countriesError, isError]);

  const getCountryName = useCallback(
    (countryId) => {
      if (!Array.isArray(countries)) {
        return "Unknown";
      }

      const country = countries.find((country) => country.id === countryId);
      return country ? `${country.name}` : "Unknown";
    },
    [countries]
  );

  const transformClients = useCallback(() => {
    if (clients?.users) {
      const transformedClients = clients.users.map((client) => ({
        ...client,
        country: getCountryName(client.countryId),
      }));

      setClientsData(transformedClients);
    }
  }, [clients?.users, setClientsData, getCountryName]);

  useEffect(() => {
    transformClients();
  }, [transformClients]);

  return <ClientsLayout data={clientsData} />;
};

export default Clients;
